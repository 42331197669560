/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ITEMS_TABLE_CONFIG } from '~/entities/products/lib-items'
import { Item, OptionDetails, Options } from '~/shared/api'
import Spinner from '~/shared/ui/spinner'
import { CustomSelect, Empty, TableContainer } from '~/shared/ui'
import { useStore } from '~/app/store'
import { SVGPrint } from '~/shared/ui/SVG/SVGPrint'
import { ModalBarcodePrinting } from '~/features/products/crud/product-general/Modal-barcode-printing'

export const SubRowComponentFirst = observer((props: { id: string }) => {
  const { productsStore } = useStore()
  const { getItems, productsSubContentOpens } = productsStore
  const [items, setItems] = useState<undefined | Item[]>(undefined)
  const [options, setOptions] = useState<undefined | Options>(undefined)
  const [detailOptions, setDetailOptions] = useState<
    undefined | OptionDetails[]
  >(undefined)
  const [loading, setLoading] = useState(false)

  const [openModal, setOpenModal] = useState(false)

  const [selectOptions, setSelectOptions] = useState<{
    [key: string]: { id: string; label: string }
  }>({})

  const hasFilters = Object.values(selectOptions).some(Boolean)
  useEffect(() => {
    if (productsSubContentOpens.includes(props.id)) {
      setLoading(true)
      getItems(props.id).then((data) => {
        setItems(data?.items)
        setOptions(data?.options)
        setDetailOptions(data?.detailOptions)
        setLoading(false)
      })
    } else {
      setItems(undefined)
    }
  }, [getItems, props.id, productsSubContentOpens])

  return items && items.length ? (
    <div className="p-6">
      <div className="flex justify-between items-center p-4 ">
        <div className="flex justify-between gap-3">
          {options &&
            Object.keys(options).map((option) => {
              const currentOptions = options[option].map((opt) => ({
                value: opt.id,
                label: opt.name,
              }))
              const selected = selectOptions[option]?.id
                ? {
                    value: selectOptions[option].id,
                    label: selectOptions[option].label,
                  }
                : null
              return (
                <div key={option}>
                  <div className="flex justify-between">
                    <CustomSelect
                      labelType="floating"
                      placeholder={option}
                      value={selected}
                      options={currentOptions}
                      key={option}
                      className="w-[200px] pr-4"
                      isClearable
                      onChange={(newValue: unknown | null) => {
                        const selectOption = newValue as {
                          label: string
                          value: string
                        }
                        setSelectOptions((prev) => ({
                          ...prev,
                          [option]: {
                            id: selectOption?.value || '',
                            label: selectOption?.label || '',
                          },
                        }))
                      }}
                    />
                  </div>
                </div>
              )
            })}
        </div>

        <div
          onClick={() => {
            setOpenModal(true)
          }}
          className="cursor-pointer"
        >
          <SVGPrint size="30" />
        </div>
      </div>
      <div className="w-full border border-[#E0E0E0] rounded-t-xl rounded-b-md">
        <TableContainer
          data={
            hasFilters
              ? items.filter((item) => {
                  return Object.values(selectOptions)
                    .filter((selectOption) => selectOption.id)
                    .map((selectOption) => selectOption.id)
                    .every((optionValueId) =>
                      item.optionValues.includes(optionValueId),
                    )
                })
              : items
          }
          aroundwithout
          variant="default"
          columns={ITEMS_TABLE_CONFIG}
          alignBodyStyle="align-top"
          className="w-full"
        />
      </div>
      <ModalBarcodePrinting
        id={props.id}
        isOpen={openModal}
        onClose={(e) => {
          e.preventDefault()
          setOpenModal(false)
        }}
        options={detailOptions}
      />
    </div>
  ) : loading ? (
    <div className="flex justify-center my-5">
      <Spinner />
    </div>
  ) : productsSubContentOpens.includes(props.id) ? (
    <div className="flex justify-center my-5">
      <Empty />
    </div>
  ) : (
    ''
  )
})
