import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { Item } from '~/shared/api'
import { ArrowToDown } from '~/shared/ui'

export const ArrowToDownProduct = observer((props: { originalData: Item }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <div
        className="flex items-center gap-x-12 w-[550px] justify-between"
        onClick={() => {
          setOpen(!open)
        }}
      >
        <div className="pl-2 whitespace-normal">
          {props?.originalData?.name}
        </div>
        <motion.div
          className="flex justify-center"
          animate={{ rotate: open ? 180 : 0 }}
          initial={{ rotate: 0 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          <ArrowToDown color="gray" width={26} height={26} />
        </motion.div>
      </div>
      {open && (
        <div className="my-4 w-[550px]">
          <div className="flex gap-4 w-[550px]">
            <div className=" border border-gray-200 w-[250px] rounded-lg">
              <div className="bg-[#EBEBEB] rounded-lg flex  h-[35px] justify-between items-center p-3 opacity-70 ">
                <strong> {t('InTheOrder')} </strong>
                <strong className="text-black bg-gray-400 w-[40px] h-[25px] rounded-xl text-center pt-0.5">
                  {props.originalData?.countInOrders}
                </strong>
              </div>
              <div className="flex justify-around  p-1 border-b-2">
                <p className="text-gray-600">{t('order number')}</p>
                <p className="text-gray-600"> {t('quantity')}</p>
              </div>
              {Object.keys(props.originalData.orders).map((orderId, i, arr) => (
                <div
                  className={`flex justify-around  p-1  ${
                    arr.length - 1 === i ? '' : 'border-b-2'
                  }`}
                  key={orderId}
                >
                  <p className="text-gray-600 ">
                    {props.originalData.orders[orderId].number}
                  </p>
                  <p className="text-gray-600">
                    {props.originalData.orders[orderId].quantity}
                  </p>
                </div>
              ))}
            </div>

            <div className=" border border-gray-200 w-[250px] rounded-lg">
              <div className="bg-[#FFEBEE] rounded-lg flex  h-[35px] justify-between items-center p-3">
                <strong className="text-[#FF5252]">{t('IsRequired')} </strong>
                <strong className="text-white bg-[#FF5252] w-[40px] h-[25px] rounded-xl text-center pt-0.5">
                  {props.originalData?.countInDemands}
                </strong>
              </div>
              <div className="flex justify-around  p-1 border-b-2">
                <p className="text-gray-600">{t('order number')}</p>
                <p className="text-gray-600">{t('quantity')}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
})
