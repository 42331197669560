/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react'
import Select, { ActionMeta, MultiValue, Props } from 'react-select'
import css from 'classnames'
import { useTranslation } from 'react-i18next'
import { SelectOption } from './types'

type P = Props & {
  customLabel?: string | undefined | boolean
  value: MultiValue<SelectOption> | SelectOption | null
  isListOptions?: boolean
  helperText?: string
  status?: 'warning' | 'error'
  variant?: 'secondary'
  labelType?: string | undefined | boolean
  isMultiCountries?: boolean
  allwaysOn?: boolean
}

export const CustomSelect: FC<P> = ({
  value,
  options,
  onChange,
  onInputChange,
  className,
  isMulti,
  customLabel,
  isListOptions,
  placeholder,
  helperText,
  status,
  variant,
  labelType,
  isDisabled,
  isLoading,
  isMultiCountries,
  allwaysOn,
  ...props
}) => {
  const { t } = useTranslation()

  const handleRemove = (id: string) => {
    const data =
      Array.isArray(value) && value?.filter((item) => item.value !== id)

    onChange?.(data, {} as ActionMeta<unknown>)
  }

  return (
    <div className="flex relative flex-col gap-1  ">
      {customLabel && labelType !== 'floating' ? (
        <span className="text-sm text-slate-500 ">{customLabel}</span>
      ) : null}
      {customLabel && labelType === 'floating' ? (
        <label
          className={css(
            'absolute z-100 label-autofill text-gray-400 text-[10px] left-0 ml-2 mt-0.5  bg-none px-1 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:ml-2 peer-focus: peer-focus:px-1 peer-focus:text-[12px]',
          )}
        >
          {customLabel}
        </label>
      ) : null}
      <Select
        {...props}
        isLoading={isLoading}
        isDisabled={isDisabled}
        options={options}
        value={value}
        onChange={(data, meta) => {
          onChange?.(data, meta)
        }}
        onInputChange={(e, action) => {
          if (action.prevInputValue !== e) {
            onInputChange?.(e, action)
          }
        }}
        placeholder={placeholder}
        menuPortalTarget={document.body}
        className={css(className)}
        classNames={{
          control: () => css('relative'),
          singleValue: () => 'absolute top-2  mt-[-4px]',
          placeholder: () => 'absolute bottom-1  text-[14px] ',
          clearIndicator: () => 'hover:text-slate-300',
        }}
        noOptionsMessage={() => `${t('dataNotFound')}!`}
        styles={{
          multiValueLabel: (base) => ({
            ...base,
            display: isListOptions ? 'none' : 'flex',
          }),
          multiValue: (base) => ({
            ...base,
            display: isListOptions ? 'none' : 'flex',
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          multiValueRemove: (base) => ({
            ...base,
            display: isListOptions ? 'none' : 'flex',
          }),

          placeholder: (base) => ({
            ...base,
            color: '#9CA3AF',
            marginTop: '10px',
          }),
          control: (base, state) => ({
            ...base,
            borderRadius: '8px',
            minHeight: '48px',

            boxShadow: state.isFocused ? 'none' : 'none',

            borderColor: '#D1D1D1 !important',

            ...(variant &&
              variant === 'secondary' && {
                background: 'rgb(243 244 246)',
                borderColor: '#D1D1D1 !important',
                borderBottomColor: '#616161 !important',
              }),

            ...(status === 'error' &&
              Array.isArray(value) &&
              value.length === 0 && {
                border: '1px solid rgba(220, 38, 38, 1) !important',
              }),
            ...(status === 'warning' &&
              Array.isArray(value) &&
              value.length === 0 && {
                border: '1px solid rgba(234, 179, 8, 1) !important',
              }),
            ...(status === 'warning' &&
              isMultiCountries && {
                border: '1px solid rgba(234, 179, 8, 1) !important',
              }),
          }),
          menuList: (base) => ({
            ...base,
            padding: 0,
          }),
          menu: (base) => {
            return {
              ...base,
              overflow: 'hidden',
              overflowY: 'auto',
            }
          },

          option: (base, state) => {
            return {
              ...base,
              background: state.isFocused ? 'rgba(165, 165, 165, 0.5)' : '',
              color: state.isFocused ? 'white' : '',
            }
          },
          clearIndicator: (base) => ({
            ...base,
            cursor: 'pointer',
            display: isListOptions ? 'none' : 'block',
          }),
        }}
        isMulti={isMulti}
      />

      {helperText && Array.isArray(value) && value.length === 0 ? (
        <span
          className={css('0 text-[12px]', {
            'text-yellow-500': status === 'warning',
          })}
        >
          {helperText}
        </span>
      ) : null}

      {helperText && !Array.isArray(value) ? (
        <span
          className={css('0 text-[12px]', {
            'text-red-500': status === 'error',
            'text-yellow-500': status === 'warning',
          })}
        >
          {helperText}
        </span>
      ) : null}

      {isListOptions && Array.isArray(value) && value.length > 0 ? (
        <div className="mt-4">
          {value.map((item: SelectOption) => (
            <div
              className="flex items-center justify-between group hover:bg-gray-100 transition py-2 px-4 rounded  "
              key={item.value}
            >
              <p className="text-[16px]">{item.label}</p>

              <span
                onClick={() => handleRemove(item.value)}
                className="text-[16px] px-2 cursor-pointer text-gray-600 hover:text-gray-800 opacity-0  group-hover:opacity-100"
              >
                &times;
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
