/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Button, CrossRemoveSVG, PageTitle } from '~/shared/ui'
import { ModalWindow } from '~/shared/ui/ModalWindow'
import { PropsModalWindow } from '~/shared/ui/ModalWindow/type'
import { useToast } from '~/shared/lib'
import { useStore } from '~/app/store'
import { Input } from '~/shared/ui/input'
import { SVGDone } from '~/shared/ui/SVG/SVGDone'

interface ValidateError {
  validate: boolean
  message: string
}

export const ModalMandatorymarkField: FC<PropsModalWindow> = observer(
  ({ isOpen, onClose, id, orderId, originalData: mandatoryMarkField }) => {
    const { t } = useTranslation()
    const toast = useToast()
    const { deliveryStore } = useStore()
    const {
      setInputmandatoryMark,
      inputMandatoryMark,
      clearInputmandatoryMark,
    } = deliveryStore
    const [validate, setValidate] = useState<null | ValidateError>(null)

    const validateInput = (value: string): ValidateError | null => {
      const trimmedValue = value.trimStart()

      if (trimmedValue === 'zzz') {
        return null
      }

      if (!trimmedValue) {
        return { validate: false, message: t('requiredField') }
      }
      if (trimmedValue.length <= 80) {
        return {
          validate: false,
          message: 'Значение должно быть длиннее 80 символов',
        }
      }
      if (/[а-яА-ЯёЁ]/.test(trimmedValue)) {
        return {
          validate: false,
          message: 'Поле не должно содержать кириллицу',
        }
      }
      return null
    }

    const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      const validationError = validateInput(inputMandatoryMark)
      if (validationError) {
        setValidate(validationError)
        return
      }

      if (!id) return

      deliveryStore.setOrderItems(
        id,
        'markValue',
        deliveryStore.inputMandatoryMark,
      )

      deliveryStore
        .validateMarks(orderId as string, {
          itemId: id,
          [mandatoryMarkField]: deliveryStore.inputMandatoryMark,
        })
        .then(() => {
          toast.open('QR-code успешно прочитан!', 'success')
          deliveryStore.setOrderItems(id, 'checked', true)
          clearInputmandatoryMark()
          setValidate(null)
          onClose(e)
        })
        .catch((error: ValidateError) => {
          setValidate(error)
        })
    }

    const handleClose = (e?: React.MouseEvent | Event) => {
      clearInputmandatoryMark()
      setValidate(null)
      onClose(e)
    }

    const statusMess = validate?.message || ''

    return (
      <ModalWindow onClose={handleClose} isOpen={isOpen} maxWidth>
        <div className="flex flex-col gap-3">
          <div className="flex justify-between py-3 w-[400px]">
            <PageTitle title="QR-code" size="text-[20px]" />
            <CrossRemoveSVG onChange={handleClose} size="24" />
          </div>
          <span className="text-[#707070]">
            Товар подлежит обязательной маркировке
          </span>
          <div>
            <Input
              id={`${id}QR-code`}
              labelType="floating"
              label="QR-code"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInputmandatoryMark(e.target.value)
                setValidate(null)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleConfirm(e as any)
                }
              }}
              status={validate ? 'error' : null}
              helperText={statusMess}
              value={deliveryStore.inputMandatoryMark}
            />
          </div>
        </div>

        <div className="flex items-center justify-center p-6 space-x-2">
          <Button intent="secondary" onClick={handleClose} type="button">
            {t('Cancel2')}
          </Button>
          <Button intent="success" onClick={handleConfirm}>
            <SVGDone color="white" size="24" />
            {t('Confirm')}
          </Button>
        </div>
      </ModalWindow>
    )
  },
)
