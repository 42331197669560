/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import { CrossRemoveSVG } from '..'

interface RoundButtonProps {
  onChange?: (event: any) => void
  className?: string
}

const RoundButton: React.FC<RoundButtonProps> = ({ onChange, className }) => {
  return (
    <button
      type="button"
      className={`w-1 h-1 first:rounded-full absolute right-1 top-[-11px]  p-2 bg-[#2563eb] hover:bg-blue-500 disabled:opacity-50 disabled:hover:bg-[#2563eb] ${className}`}
    >
      <div className="absolute top-[2px] left-[2px]">
        <CrossRemoveSVG onChange={onChange} size="12" color="white" />
      </div>
    </button>
  )
}

export default RoundButton
