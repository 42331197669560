import { FC, useEffect, useState } from 'react'
import { TabsContent, TabsHeader } from './components'
import { Props } from './types'

export const Tabs: FC<Props> = ({
  tabsContent,
  tabsHeader,
  tabsType,
  tabsHeaderClasses,
  renderIconTabHeaderEnd,
  tabError,
  tabWarning,
  customHandler,
  needOneTab,
  className,
  activeTab,
}) => {
  const [openTab, setOpenTab] = useState(activeTab ? activeTab - 1 : 0)
  const handleActiveTab = (id: number) => setOpenTab(id)

  useEffect(() => {
    if (activeTab) {
      handleActiveTab(activeTab - 1)
    }
  }, [activeTab])

  return (
    <div className={`w-full ${className}`}>
      <div className="flex items-center gap-3 overflow-x-auto">
        {!needOneTab && tabsHeader.length === 1 ? null : (
          <TabsHeader
            tabError={tabError}
            tabWarning={tabWarning}
            className={tabsHeaderClasses}
            renderIconTabHeaderEnd={renderIconTabHeaderEnd}
            tabsType={tabsType}
            setTab={handleActiveTab}
            items={tabsHeader}
            activeTab={openTab}
            customHandler={customHandler}
          />
        )}

        {tabsHeader.length > 1 ? renderIconTabHeaderEnd : null}
      </div>

      <TabsContent
        tabsType={tabsType}
        items={tabsContent}
        activeTab={openTab}
      />
    </div>
  )
}
