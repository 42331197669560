import { AxiosResponse } from 'axios'
import { apiService } from '../../api-base'
import { OrderData, OrdersData, PrintBlankPayload } from './types'

export class DeliveryService {
  static getShippingOrders(): Promise<AxiosResponse<OrdersData>> {
    return apiService.get<AxiosResponse<OrdersData>>('/shipping/orders')
  }

  static getShippingOrderById(id: string): Promise<AxiosResponse<OrderData>> {
    return apiService.get<AxiosResponse<OrderData>>(`/shipping/${id}`)
  }

  static printBlanc(
    data: PrintBlankPayload,
    orderId: string,
    action: string,
  ): Promise<AxiosResponse> {
    return apiService.post(`/shipping/${orderId}/${action}`, data)
  }

  static checkingItemsOrderById(
    orderId: string,
    data: { mandatoryMarks: { [key: string]: string } },
  ): Promise<AxiosResponse> {
    return apiService.post(`/shipping/${orderId}/checking`, data)
  }

  static getProductByBarcode(barcode: string): Promise<AxiosResponse> {
    return apiService.get<AxiosResponse>(
      `/products/variation/barcode/${barcode}`,
    )
  }

  static validateMarks(
    orderId: string,
    data: { itemId: string; [key: string]: string },
  ): Promise<AxiosResponse> {
    return apiService.post(`/shipping/${orderId}/validate-marks`, data)
  }
}
