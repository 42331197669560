import { FC } from 'react'
import { ButtonProps } from './types'
import { button } from './config'
import { Spinner } from '../spinner'

export const Button: FC<ButtonProps> = ({
  className,
  intent,
  size,
  type = 'button',
  onClick,
  children,
  disabled = false,
  isLoading = false,
  hasBlueDot = false,
  badgeContent,
}) => {
  const badgeClass =
    typeof badgeContent === 'string' && badgeContent.startsWith('-')
      ? 'bg-red-500 text-white'
      : 'bg-white text-[#242424] border border-gray-300'

  const badgeWidthClass =
    badgeContent && badgeContent.toString().length > 2
      ? 'min-w-[65px] px-1'
      : 'min-w-[22px]'

  const badgeLeftClass =
    badgeContent && badgeContent.toString().length > 2
      ? 'left-[73px]'
      : 'left-[118px]'

  return (
    <button
      onClick={onClick}
      type={type}
      disabled={!!(isLoading || disabled)}
      className={button({ intent, size, className })}
      style={{ position: 'relative' }}
    >
      {isLoading ? <Spinner /> : null}
      {children}
      {hasBlueDot && (
        <span className="absolute top-[5px] right-[5px] w-[7px] h-[7px] rounded-[50%] bg-[#2563eb]" />
      )}
      {badgeContent && (
        <span
          className={`absolute bottom-[35px] ${badgeLeftClass} flex items-center justify-center ${badgeClass} ${badgeWidthClass} text-[11px] h-[20px] rounded-full whitespace-nowrap`}
        >
          {badgeContent}
        </span>
      )}
    </button>
  )
}
