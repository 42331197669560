import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs } from '~/shared/ui'
import { OrdersItemsTable } from '~/features/delivery/shipping-order-by-id/ui/OrderItemsTable'
import { PrintedForm } from '~/features/delivery/shipping-order-by-id/ui/PrintedForm'

export const DeliveryTabs = observer(() => {
  const { t } = useTranslation()

  const tabHeaderDelivery = useMemo(() => {
    return [
      { id: 1, label: t('productsInTheOrder') },
      { id: 2, label: t('printedForm') },
    ]
  }, [t])

  const tabContenDelivery = useMemo(() => {
    return [
      {
        id: 1,
        element: <OrdersItemsTable />,
      },
      { id: 2, element: <PrintedForm /> },
    ]
  }, [])

  return (
    <div className="mt-10">
      <Tabs
        tabsType="contained"
        tabsHeader={tabHeaderDelivery}
        tabsContent={tabContenDelivery}
      />
    </div>
  )
})
