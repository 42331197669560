import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import { AxiosResponse } from 'axios'
import { Button } from '../button'
import { IconSave } from '../SVG/iconSave'
import { useToast } from '~/shared/lib'
import { apiService } from '~/shared/api'

interface FileInputProps {
  id?: string
  className?: string
  onFileSelect?: (file: File) => void
  onSubmit?: (file: File) => void
  label?: string
  placeholder?: string
}

const FileInput: React.FC<FileInputProps> = ({
  id = 'file-input',
  className,
  onFileSelect,
  onSubmit,
  label,
  placeholder = 'Выберите файл',
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const toast = useToast()

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null
    setSelectedFile(file)
    if (file && onFileSelect) {
      onFileSelect(file)
    }
  }

  const handleSubmit = async () => {
    if (selectedFile) {
      try {
        const reader = new FileReader()
        reader.onload = async () => {
          const fileContent = reader.result as string
          const response = await apiService.post<AxiosResponse>(
            'upload-report',
            fileContent,
            { headers: { 'Content-Type': 'text/csv' } },
          )

          if (!response.status) {
            throw new Error('Ошибка при загрузке файла')
          }

          toast.open('Файл успешно загружен!', 'success')

          setSelectedFile(null)

          if (onSubmit) {
            onSubmit(selectedFile)
          }
        }
        reader.readAsText(selectedFile)
      } catch (error) {
        toast.open('Ошибка при отправке файла!', 'danger')
      }
    }
  }

  const handleClickInput = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <div className={classNames('relative flex flex-col', className)}>
      {label && (
        <label htmlFor={id} className="mb-2 text-sm text-gray-700">
          {label}
        </label>
      )}
      <div className="flex items-center gap-2 ">
        <input
          ref={inputRef}
          id={id}
          type="file"
          className="hidden"
          onChange={handleFileSelect}
        />
        <span
          onClick={handleClickInput}
          className={classNames(
            'flex-1 px-4 py-2 text-sm border rounded-md cursor-pointer h-[42px]',
            selectedFile ? 'bg-white' : 'bg-gray-100',
          )}
        >
          {selectedFile ? selectedFile.name : placeholder}
        </span>
        <Button
          intent="success"
          onClick={handleSubmit}
          disabled={!selectedFile}
        >
          <IconSave /> Отправить
        </Button>
      </div>
    </div>
  )
}

export default FileInput
