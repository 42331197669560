import { TableProps } from './types'
import Pagination from '../pagination'
import { TableBody } from '../table-body'
import { table as tableEL } from './config'
import { TableHead } from '../table-head'
import { usePagination, useTable } from '../../hooks'
import { useContextTableMenu } from '../../hooks/use-context'
import { ContextMenu } from '../context-menu'
import Spinner from '~/shared/ui/spinner'

export const Table = <T, K extends keyof T>({
  data = [],
  columns = [],
  className,
  pagination,
  rowContextMenu,
  onRowClick,
  loadMore,
  fixedWidth,
  rounded,
  aroundwithout,
  hasSubRowContent,
  subRowComponent,
  variant = 'default',
  border,
  alignBodyStyle,
}: TableProps<T, K>) => {
  // Define React Table
  const { table } = useTable({ data, columns, pagination })

  // Pagination
  const {
    firstPage,
    isPreviousPage,
    previousPage,
    nextPage,
    isNextPage,
    lastPage,
    currentPage,
    allPages,
    pageSize,
    handlePagination,
  } = usePagination({ table })

  // Context
  const { handleContextMenu, anchorPoint, isShown, row } =
    useContextTableMenu<T>()

  // Table Data
  const dataSource = table.getRowModel().rows
  const columnsData = table.getHeaderGroups()
  return (
    <div>
      <table
        className={fixedWidth ? tableEL({ className, rounded }) : className}
      >
        <TableHead
          columns={columnsData}
          aroundwithout={aroundwithout}
          border={border}
          variant={variant}
        />
        <TableBody
          data={dataSource}
          rounded={rounded}
          onRowClick={onRowClick}
          onRowContextMenu={handleContextMenu}
          hasSubRowContent={hasSubRowContent}
          subRowComponent={subRowComponent}
          cellsCount={columnsData[0].headers.length}
          alignBodyStyle={alignBodyStyle}
        />
      </table>{' '}
      {loadMore ? (
        <div className="flex justify-center my-5">
          <Spinner />
        </div>
      ) : null}
      <ContextMenu
        top={anchorPoint.y}
        left={anchorPoint.x}
        isShown={isShown}
        items={rowContextMenu}
        row={row}
      />
      {pagination ? (
        <div className="flex items-center gap-2">
          <Pagination
            allPages={allPages}
            currentPage={currentPage}
            firstPage={firstPage}
            nextPage={nextPage}
            lastPage={lastPage}
            previousPage={previousPage}
            isNextPage={isNextPage}
            isPreviousPage={isPreviousPage}
            pageSize={pageSize}
            handlePagination={handlePagination}
          />
        </div>
      ) : null}
    </div>
  )
}

export default Table
