import React from 'react'

interface Props {
  size?: string
  color?: string
}

export const ArrowLeftSVG: React.FC<Props> = ({ color, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13.4731 17.1269L8.97892 12.6327C8.88532 12.5391 8.81929 12.4388 8.78084 12.3317C8.74238 12.2247 8.72314 12.1141 8.72314 12C8.72314 11.8795 8.74238 11.7673 8.78084 11.6635C8.81929 11.5596 8.88532 11.4609 8.97892 11.3673L13.4731 6.87309C13.618 6.7282 13.7936 6.65576 14 6.65576C14.2064 6.65576 14.3821 6.7282 14.5269 6.87309C14.6718 7.01795 14.7443 7.19359 14.7443 7.39999C14.7443 7.60639 14.6718 7.78202 14.5269 7.92689L10.4539 12L14.5269 16.0731C14.6718 16.218 14.7443 16.3936 14.7443 16.6C14.7443 16.8064 14.6718 16.982 14.5269 17.1269C14.3821 17.2718 14.2064 17.3442 14 17.3442C13.7936 17.3442 13.618 17.2718 13.4731 17.1269Z"
        fill={color || '#707070'}
      />
    </svg>
  )
}
