import { ChangeEvent, FC } from 'react'
import css from 'classnames'
import { TabsHeaderProps } from './types'
import { tabsHeader } from './config'

const TabsHeader: FC<TabsHeaderProps> = ({
  className,
  setTab,
  items = [],
  activeTab,
  tabsType,
  tabError,
  tabWarning,
  customHandler,
}) => {
  const isFullWidth = items.length > 3

  return (
    <ul
      className={css(
        tabsHeader({ className }),
        {
          'bg-white w-fit': tabsType === 'contained' && !isFullWidth,
          'w-full  bg-white': tabsType === 'contained' && isFullWidth,
        },

        'p-1 rounded-lg border border-[#E0E0E0] flex  ',
      )}
    >
      {items?.map((item, i) => {
        return (
          <li key={item.id} className="transition-all relative">
            <span
              onClick={(e: unknown) => {
                setTab(i)
                if (customHandler) {
                  customHandler(
                    e as ChangeEvent<HTMLInputElement>,
                    item.mapperCode,
                  )
                }
              }}
              className={css(
                `cursor-pointer ${
                  item.label === 'Список заказов' || item.label === 'В работе'
                    ? 'text-[14px] py-1 h-[28px]'
                    : 'text-[16px] py-1 h-[34px]'
                }  w-full text-center inline-block px-5 text-gray-600  `,
                {
                  'bg-white  rounded-t border-r border-l border-t border-b border-b-white':
                    activeTab === i && tabsType !== 'contained',
                },
                {
                  'bg-[#EBEBEB] rounded':
                    tabsType === 'contained' && activeTab === i,
                },
                {
                  'border-none': tabsType === 'contained',
                },
              )}
            >
              {item.label}
            </span>

            {tabError?.filter(Boolean).length ||
            tabWarning?.filter(Boolean).length ? (
              <span
                className={css(
                  'absolute w-1.5 h-1.5 top-2 right-2 rounded-full',

                  {
                    'bg-yellow-500': tabWarning?.includes(i + 1),
                  },
                )}
              />
            ) : null}

            {tabsType === 'contained' ? (
              <span
                className={css({
                  'absolute h-[3px] bottom-[0px] rounded-full w-[60px] left-0 right-0 mx-auto bg-blue-600 z-5':
                    i === activeTab,
                })}
              />
            ) : null}
          </li>
        )
      })}
    </ul>
  )
}

export default TabsHeader
