import { FC } from 'react'

interface SaveSVGProps {
  size?: string
}

export const IconSave: FC<SaveSVGProps> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.3077C3.5 4.80257 3.675 4.375 4.025 4.025C4.375 3.675 4.80257 3.5 5.3077 3.5H16.0404C16.2814 3.5 16.5131 3.5468 16.7355 3.6404C16.958 3.73398 17.1513 3.86282 17.3154 4.02692L19.973 6.6846C20.1371 6.8487 20.266 7.04198 20.3596 7.26443C20.4531 7.48686 20.5 7.71858 20.5 7.9596V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077ZM19 7.84998L16.15 4.99997H5.3077C5.21795 4.99997 5.14423 5.02883 5.08653 5.08653C5.02883 5.14423 4.99997 5.21795 4.99997 5.3077V18.6923C4.99997 18.782 5.02883 18.8557 5.08653 18.9134C5.14423 18.9711 5.21795 19 5.3077 19H18.6923C18.782 19 18.8557 18.9711 18.9134 18.9134C18.9711 18.8557 19 18.782 19 18.6923V7.84998ZM12 17.2692C12.6923 17.2692 13.282 17.0256 13.7692 16.5384C14.2564 16.0512 14.5 15.4615 14.5 14.7692C14.5 14.0769 14.2564 13.4872 13.7692 13C13.282 12.5128 12.6923 12.2692 12 12.2692C11.3077 12.2692 10.7179 12.5128 10.2308 13C9.74359 13.4872 9.5 14.0769 9.5 14.7692C9.5 15.4615 9.74359 16.0512 10.2308 16.5384C10.7179 17.0256 11.3077 17.2692 12 17.2692ZM7.28845 9.88458H13.6923C13.95 9.88458 14.165 9.79836 14.3375 9.62593C14.5099 9.45349 14.5961 9.23843 14.5961 8.98075V7.28845C14.5961 7.03077 14.5099 6.81571 14.3375 6.64328C14.165 6.47084 13.95 6.38462 13.6923 6.38462H7.28845C7.03077 6.38462 6.81571 6.47084 6.64328 6.64328C6.47084 6.81571 6.38463 7.03077 6.38463 7.28845V8.98075C6.38463 9.23843 6.47084 9.45349 6.64328 9.62593C6.81571 9.79836 7.03077 9.88458 7.28845 9.88458Z"
        fill="white"
      />
    </svg>
  )
}
