import { Outlet, useLocation } from 'react-router-dom'
import { Header } from '~/widgets'

export const BaseLayout = () => {
  const location = useLocation()

  const nav = <Header />

  return (
    <div className="min-h-screen h-auto bg-[#F0F0F0] text-gray-700">
      <div>
        {nav}
        <div
          className={`w-full ${
            location.pathname !== '/' && 'py-3 px-6'
          } overflow-x-auto`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  )
}
