import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiService, Restock } from '../..'
import { Statistics } from './types'
import { Stores } from '../stores/types'

export class MainService {
  static getStatistics(storeId: string): Promise<AxiosResponse<Statistics>> {
    return apiService.get<AxiosResponse<Statistics>>(
      `/stores/${storeId}/statistics`,
    )
  }

  static getRestock(storeId: string): Promise<AxiosResponse<Restock[]>> {
    return apiService.get<AxiosResponse<Restock[]>>(
      `/products/restock?store_id=${storeId}`,
    )
  }

  static getStores(
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Stores[]>> {
    return apiService.get<AxiosResponse<Stores[]>>(`/stores`, config)
  }
}
