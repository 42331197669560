import { SelectOption } from '~/shared/ui'

export const mapDataForSelect = <T>(
  data: T[],
  valueKey: keyof T | null,
  labelKey: keyof T | null,
): SelectOption[] => {
  const seenValues = new Set<string>()
  return data
    ?.filter((item) => {
      const value = String(item?.[valueKey as keyof T] || '')
      if (seenValues.has(value)) {
        return false // Убираем дубликат
      }
      seenValues.add(value)
      return true
    })
    ?.map((item) => ({
      label: String(item?.[labelKey as keyof T] || ''),
      value: String(item?.[valueKey as keyof T] || ''),
    }))
}
