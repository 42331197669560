import React from 'react'
import { NotFound } from './not-found'
import { RegistrationPage } from './registration'
import Users from './users'
import { UserEditFeature } from '~/features/user-edit'
import { RoleEditFeature } from '~/features/role-edit'
import { AssemblyByType } from '~/features/assembly-by-type'
import { ShippingOrderById } from '~/features/delivery/shipping-order-by-id/ui/ShippingOrderById'
import UploadReport from './upload-report'

const LoginPage = React.lazy(() => import('./login'))
const MainPage = React.lazy(() => import('./main'))
const Assembli = React.lazy(() => import('./assembling'))
const Delivery = React.lazy(() => import('./delivery'))
const ProductsPage = React.lazy(() => import('./products'))
const SettingsPage = React.lazy(() => import('./settings'))
const RoleCreate = React.lazy(() => import('./role-create'))
const UsersSlug = React.lazy(() => import('./users/user-details'))
const ProfilePage = React.lazy(() => import('./profile'))
const CategoriesCrudPage = React.lazy(() => import('./categories-crud'))
const ProductsCategories = React.lazy(() => import('./categories'))
const ProductsCrudPage = React.lazy(() => import('./products-crud'))
const ShopsPage = React.lazy(() => import('./stores'))
const StoresCrudPage = React.lazy(() => import('./stores-crud'))
const OrdersPage = React.lazy(() => import('./orders'))
const RetailPage = React.lazy(() => import('./retail'))
const OrdersCrudPage = React.lazy(() => import('./orders-crud'))
const WarehousesPage = React.lazy(() => import('./warehouses'))
const AssemblyByIdPage = React.lazy(
  () => import('./assembling/assembly-by-id-page'),
)

const MarketingPage = React.lazy(
  () => import('./marketing/marketing-discounts/index'),
)
const MarketingCoupons = React.lazy(
  () => import('./marketing/marketing-coupons/index'),
)
const MarketingSlider = React.lazy(
  () => import('./marketing/marketing-slider/index'),
)
const CreateNewArrival = React.lazy(
  () => import('./warehouse-crud/createNewArrival'),
)
const ViewingReceipt = React.lazy(
  () => import('./warehouse-crud/viewingReceipts'),
)

export const publicRoutes = [
  {
    Component: LoginPage,
    path: '/login',
  },
  {
    Component: RegistrationPage,
    path: '/registration',
  },
]

export const privateRoutes = [
  {
    Component: MainPage,
    path: '/',
  },
  {
    Component: UploadReport,
    path: '/upload-report',
  },
  {
    Component: ProductsPage,
    path: '/products',
  },
  {
    Component: ProductsCrudPage,
    path: '/products/create',
  },
  {
    Component: ProductsCrudPage,
    path: '/products/:id/edit',
  },
  {
    Component: ProductsCategories,
    path: '/categories',
  },
  {
    Component: CategoriesCrudPage,
    path: '/categories/:id/edit',
  },
  {
    Component: CategoriesCrudPage,
    path: '/categories/create',
  },
  {
    Component: ShopsPage,
    path: '/stores',
  },
  {
    Component: StoresCrudPage,
    path: '/stores/:id/edit',
  },
  {
    Component: OrdersPage,
    path: '/orders',
  },
  {
    Component: RetailPage,
    path: '/retail',
  },

  {
    Component: OrdersCrudPage,
    path: '/orders/:id/edit',
  },
  {
    Component: WarehousesPage,
    path: '/incoming',
  },
  {
    Component: CreateNewArrival,
    path: '/incoming/create',
  },
  {
    Component: ViewingReceipt,
    path: '/incoming/:number/view',
  },
  {
    Component: MarketingPage,
    path: '/discounts',
  },
  {
    Component: MarketingCoupons,
    path: '/coupons',
  },
  {
    Component: MarketingSlider,
    path: '/slider',
  },
  {
    Component: Users,
    path: '/users',
  },
  {
    Component: Assembli,
    path: '/assembly/orders',
  },
  {
    Component: Delivery,
    path: 'shipping/orders',
  },
  {
    Component: AssemblyByType,
    path: '/assembly/orders/:type',
  },
  {
    Component: AssemblyByIdPage,
    path: '/assembly/:id/items',
  },

  { path: '/shipping/checking/:id/view', Component: ShippingOrderById },
  { path: '/users/:id/edit', Component: UserEditFeature },
  { path: '/userRoles/:id/edit', Component: RoleEditFeature },
  { path: '/user/create', Component: UsersSlug },
  { path: '/profile/:id/edit', Component: ProfilePage },
  { path: '/role/create', Component: RoleCreate },
  {
    Component: SettingsPage,
    path: '/settings',
  },
  {
    Component: NotFound,
    path: '/*',
  },
]
