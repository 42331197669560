import { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { Store } from '~/shared/api/services/global/types'
import { MainService } from '~/shared/api/services/main'
import { Statistics } from '~/shared/api/services/main/types'
import { SelectOption } from '~/shared/ui'
import { Restock } from '~/shared/api'

export class MainModel {
  loading = false

  statistics: Statistics | null = null

  filterStore: SelectOption | null = null

  restockProducts: Restock[] = []

  constructor() {
    makeAutoObservable(this)
  }

  getStatistics = async (storeId: string) => {
    try {
      this.loading = true
      this.statistics = null
      const { data } = await MainService.getStatistics(storeId)

      runInAction(() => {
        this.statistics = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  getRestock = async (storeId: string) => {
    try {
      this.loading = true
      this.restockProducts = []

      const { data } = await MainService.getRestock(storeId)
      runInAction(() => {
        this.restockProducts = data
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  getStoresMain = async (store: Store) => {
    runInAction(() => {
      this.filterStore = {
        label: store.name,
        value: store.id,
      }
      this.getStatistics(store.id)
      this.getRestock(store.id)
    })
  }

  setFilterStore = (value: SelectOption) => {
    this.filterStore = value
    const storeId = value.value

    this.getStatistics(storeId)
    this.getRestock(storeId)
  }
}

export default MainModel
