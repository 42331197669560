/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */
interface DebounceOptions {
  leading?: boolean // Выполнить функцию в начале ожидания
  trailing?: boolean // Выполнить функцию в конце ожидания
}

const debounce = <T extends (...args: any[]) => any>(
  fn: T,
  ms = 300,
  options: DebounceOptions = {},
) => {
  const { leading = false, trailing = true } = options
  let timeoutId: ReturnType<typeof setTimeout> | null = null
  let lastArgs: Parameters<T> | null = null
  let lastThis: any = null
  let hasCalledLeading = false

  const clear = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
    hasCalledLeading = false
  }

  const debounced = function (this: any, ...args: Parameters<T>) {
    lastArgs = args
    lastThis = this

    if (leading && !hasCalledLeading) {
      fn.apply(this, args)
      hasCalledLeading = true
    }

    if (timeoutId) clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      if (trailing && (!leading || hasCalledLeading)) {
        fn.apply(lastThis, lastArgs as Parameters<T>)
      }
      clear()
    }, ms)
  }

  debounced.cancel = clear

  return debounced as T & { cancel: () => void }
}
export default debounce
