/* eslint-disable react/jsx-props-no-spreading */
import { ColumnDef } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import { Item } from '~/shared/api/services/delivery/types'
import { Checkbox } from '~/shared/ui'
import { SVGDone } from '~/shared/ui/SVG/SVGDone'
import { SVGNOtFoto } from '~/shared/ui/SVG/SVGNotFoto'

const CheckboxAnHonestSign = observer((props: Item) => {
  const { checked, id } = props
  return <Checkbox key={id + Date.now()} checked={checked} />
})

const CheckboxMandatoryMark = () => {
  return (
    <div>
      <button className="w-[20px] h-[20px] bg-yellow-400 rounded-lg relative">
        <div className="absolute bottom-[1px] left-[2px]">
          <SVGDone color="black" size="17px" />
        </div>
      </button>
    </div>
  )
}

export const DELIVERY_TABLE_CONFIG: ColumnDef<Item>[] = [
  {
    header: '',
    accessorKey: 'empty',

    cell: (row) => {
      return (
        <div className="ml-8">
          <CheckboxAnHonestSign {...row.cell.row.original} />
        </div>
      )
    },
    maxSize: 50,
  },
  {
    header: 'anHonestSign',
    accessorKey: 'anHonestSign',

    cell: (row) => {
      const { original } = row.cell.row
      return original.mandatoryMarkField ? (
        <div className="ml-[35px]">
          <CheckboxMandatoryMark />
        </div>
      ) : null
    },
    maxSize: 70,
  },

  {
    header: 'manufacturer',
    accessorKey: 'manufacturer',

    cell: (row) => {
      const { original } = row.cell.row

      return <div> {original.brandName}</div>
    },
    maxSize: 150,
  },
  {
    header: 'Product name',
    accessorKey: 'Product name',

    cell: (row) => {
      const { original } = row.cell.row

      return <div> {original?.productName}</div>
    },
    maxSize: 150,
  },

  {
    header: 'options',
    accessorKey: 'options',

    cell: (row) => {
      const { original } = row.cell.row
      const { options } = original

      if (!options?.image && !options?.text) {
        return null
      }

      return (
        <div className="my-4">
          {options?.image ? (
            <img
              src={options.image}
              alt="foto"
              className="w-20 h-20 rounded "
              onError={(e) => {
                ;(e.target as HTMLImageElement).style.display = 'none'
                ;(
                  e.target as HTMLImageElement
                ).parentElement?.insertAdjacentHTML(
                  'beforeend',
                  `<div class="w-full h-full rounded"><SVGNOtFoto size="80px" /></div>`,
                )
              }}
            />
          ) : (
            <SVGNOtFoto size="80px" className="w-[80px] h-[80px]" />
          )}

          {options?.text &&
            options.text.split('<br>').map((el) => <div key={el}>{el}</div>)}
        </div>
      )
    },
    maxSize: 150,
  },

  {
    header: 'image',
    accessorKey: 'image',

    cell: (row) => {
      const { original } = row.cell.row

      const imageSrc = original?.image

      return (
        <div className="w-[150px] h-[150px]  rounded shadow m-2">
          {imageSrc ? (
            <img
              className="w-full h-full  rounded"
              src={imageSrc}
              alt="Product Thumbnail"
            />
          ) : (
            <div className="w-full h-full rounded">
              <SVGNOtFoto size="150px" />
            </div>
          )}
        </div>
      )
    },
    maxSize: 100,
  },
]
