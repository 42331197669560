/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { Loaders } from './types'
import {
  Dates,
  Item,
  OrderData,
  PrintBlankInfo,
  PrintBlankPayload,
  CheckItemsPayload,
} from '~/shared/api/services/delivery/types'
import { DeliveryService } from '~/shared/api/services/delivery'
import { handleAxiosError } from '~/shared/lib'

export class DeliveryStore {
  loaders: Loaders = {
    shippingOrders: false,
    shippingOrderById: false,
    printBlanc: false,
    barcode: false,
    mandatoryMark: false,
    validateMarks: false,
  }

  shippingOrders: Dates | null = null

  shippingOrderById: OrderData | null = null

  itemsOrderById: Item[] = []

  printBlankInfo: PrintBlankInfo | null = null

  barcode = ''

  inputMandatoryMark = ''

  modalMandatoryMarkField = {
    open: false,
    id: '',
    mandatoryMarkField: '',
  }

  constructor() {
    makeAutoObservable(this)
  }

  activeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: true }
  }

  closeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: false }
  }

  setFieldValue = (name: string, newValue: string | boolean) => {
    const field = this.printBlankInfo?.fields.find((f) => f.name === name)
    if (field && !field.disabled) {
      field.value = newValue
      return
    }

    const additionalField = this.printBlankInfo?.additionalFields.find(
      (addF) => addF.name === name,
    )
    if (additionalField && !additionalField.disabled) {
      additionalField.value = newValue
    }
  }

  getShippingOrders = async () => {
    this.activeLoader('shippingOrders')
    try {
      const { data } = await DeliveryService.getShippingOrders()
      this.shippingOrders = data.ordersData.dates
      return data
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      runInAction(() => {
        this.closeLoader('shippingOrders')
      })
    }
  }

  getShippingOrderById = async (id: string) => {
    this.activeLoader('shippingOrderById')
    try {
      const { data } = await DeliveryService.getShippingOrderById(id)
      this.shippingOrderById = data
      this.itemsOrderById = data.items.map((item) => {
        return {
          ...item,
          markValue: '',
          checked: false,
        }
      })
      this.printBlankInfo = data.printBlankInfo
      return data
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      runInAction(() => {
        this.closeLoader('shippingOrderById')
      })
    }
  }

  printBlanc = async (
    dataPrintBlankInfo: PrintBlankInfo,
    orderId: string,
    action: 'downloadBlank' | 'printBlank',
  ) => {
    try {
      this.activeLoader('printBlanc')
      // eslint-disable-next-line prefer-const
      let payload = [
        ...dataPrintBlankInfo.additionalFields,
        ...dataPrintBlankInfo.fields,
      ].reduce((acc, el) => {
        const key = el.name
        acc[key] = el.value
        return acc
      }, {} as Record<string, any>)
      payload.mandatoryMarks = this.itemsOrderById.reduce((acc, item) => {
        if (item.markValue) {
          acc[item.mandatoryMarkField] = item.markValue
        }
        return acc
      }, {} as { [key: string]: string })

      const { data } = await DeliveryService.printBlanc(
        payload as PrintBlankPayload,
        orderId,
        action,
      )

      return data
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      this.closeLoader('printBlanc')
    }
  }

  setBarcode = (value: string) => {
    this.barcode = value
  }

  resetBarcode = () => {
    this.barcode = ''
  }

  getProductByBarcode = async (barcode: string) => {
    this.activeLoader('barcode')
    try {
      const { data } = await DeliveryService.getProductByBarcode(barcode)
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data.error)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('barcode')
      })
    }
  }

  setInputmandatoryMark = (value: string) => {
    this.inputMandatoryMark = value
  }

  clearInputmandatoryMark = () => {
    this.inputMandatoryMark = ''
  }

  setOrderItems = (
    id: string,
    name: 'markValue' | 'checked',
    value: boolean | string,
  ) => {
    runInAction(() => {
      this.itemsOrderById = this.itemsOrderById.map((item) =>
        item.id === id ? { ...item, [name]: value } : item,
      )
    })
  }

  checkingItemsOrderById = async (orderId: string) => {
    this.activeLoader('mandatoryMark')
    // eslint-disable-next-line prefer-const
    let payload = {} as Partial<CheckItemsPayload>
    payload.mandatoryMarks = this.itemsOrderById.reduce((acc, item) => {
      if (item.markValue) {
        acc[item.mandatoryMarkField] = item.markValue
      }
      return acc
    }, {} as { [key: string]: string })
    const payloadKeys = [
      'username',
      'postcode',
      'city',
      'region',
      'address',
      'phone',
      'company',
      'ddp',
      'weight',
      'totalPrice',
    ] as const
    payloadKeys.forEach((key) => {
      if (this.printBlankInfo) {
        const findFormField = [
          ...this.printBlankInfo.additionalFields,
          ...this.printBlankInfo.fields,
        ].find((el) => el.name === key)
        if (findFormField) {
          payload[key] = findFormField.value as any
        }
      }
    })
    const finalPayload = payload as CheckItemsPayload
    try {
      await DeliveryService.checkingItemsOrderById(orderId, finalPayload)
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      this.closeLoader('mandatoryMark')
    }
  }

  validateMarks = async (
    orderId: string,
    payload: { itemId: string; [key: string]: string },
  ) => {
    this.activeLoader('validateMarks')

    try {
      await DeliveryService.validateMarks(orderId, payload)
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      this.closeLoader('validateMarks')
    }
  }
}

export default DeliveryStore
