interface FilterSVGProps {
  color?: string
  onClick?: () => void
  size?: string
  disabled?: boolean
  className?: string
}

export const FilterSVG = (props: FilterSVGProps) => {
  const { color, size, disabled = false, onClick, className } = props

  const onClickHandler = () => {
    if (!disabled && onClick) {
      onClick()
    }
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClickHandler}
      className={`transition-opacity duration-300 ${
        disabled ? 'opacity-50' : 'opacity-100'
      } ${className}`}
    >
      <path
        d="M11.3846 19.4999C11.1334 19.4999 10.9231 19.4153 10.7538 19.2461C10.5847 19.0768 10.5 18.8666 10.5 18.6154L10.5 12.8269L4.90198 5.71539C4.70966 5.45898 4.68176 5.19232 4.8183 4.91541C4.95483 4.63849 5.1853 4.5 5.50964 4.5L18.4904 4.5C18.8147 4.5 19.0452 4.63849 19.1816 4.91541C19.3182 5.19232 19.2903 5.45898 19.098 5.71539L13.5 12.8269L13.5 18.6154C13.5 18.8666 13.4153 19.0768 13.2461 19.2461C13.0769 19.4153 12.8666 19.4999 12.6154 19.4999L11.3846 19.4999ZM12 12.3L16.95 6L7.04999 6L12 12.3Z"
        fill={color || '#1C1B1F'}
      />
    </svg>
  )
}
