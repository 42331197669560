import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { motion } from 'framer-motion'
import { useStore } from '~/app/store'
import { ArrowToDown } from '../../SVG/ArrowToDown'

export const ArrowToDownTable = observer(
  (props: {
    id: string
    storeName?:
      | 'discountStore'
      | 'marketingStore'
      | 'productsStore'
      | 'couponsStore'
    path?: 'product' | 'category'
  }) => {
    const store = useStore()
    const { productsStore } = store
    const storeSection = props.storeName && store[props.storeName]

    if (
      !storeSection ||
      typeof storeSection.setIdsSubContentOpens !== 'function'
    ) {
      return null
    }

    const { setIdsSubContentOpens } = storeSection

    const open = useMemo(
      () => productsStore.productsSubContentOpens.includes(props.id),
      [productsStore.productsSubContentOpens, props.id],
    )

    return (
      <div
        onClick={() => {
          if (!productsStore.itemsLoading) {
            setIdsSubContentOpens(props?.id)
          }
        }}
        className="cursor-pointer"
      >
        <motion.div
          className="flex justify-center"
          animate={{ rotate: open ? 180 : 0 }}
          initial={{ rotate: 0 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          <ArrowToDown width="50px" height="30px" color="gray" />
        </motion.div>
      </div>
    )
  },
)
