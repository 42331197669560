/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Button, CrossRemoveSVG, CustomSelect, PageTitle } from '~/shared/ui'
import { ModalWindow } from '~/shared/ui/ModalWindow'
import { PropsModalWindow } from '~/shared/ui/ModalWindow/type'
import { formatErrorForToast, useToast } from '~/shared/lib'
import { useStore } from '~/app/store'
import { SVGPrint } from '~/shared/ui/SVG/SVGPrint'
import { SelectedOption } from '~/shared/api/services/warehouses/types'

export const ModalBarcodePrinting: FC<PropsModalWindow> = observer(
  ({ isOpen, onClose, id, options }) => {
    const { t } = useTranslation()
    const toast = useToast()
    const { productsStore } = useStore()
    const { currentProduct, generateBarcode, optionDetails } = productsStore
    const [selectedOptions, setSelectedOptions] = useState<
      Record<string, SelectedOption>
    >({})

    const currentOptions = useMemo(
      () => (options?.length ? options : optionDetails),
      [options, optionDetails],
    )

    useEffect(() => {
      if (isOpen) {
        setSelectedOptions({})
      }
    }, [isOpen])

    const handleSelectChange = (
      optionId: string,
      selectedValue: SelectedOption,
    ) => {
      setSelectedOptions((prev) => ({
        ...prev,
        [optionId]: selectedValue,
      }))
    }

    const handleGenerateBarcode = async (
      e: React.MouseEvent<HTMLButtonElement>,
    ) => {
      try {
        const payload = {} as Record<string, string>
        Object.keys(selectedOptions).forEach((key) => {
          payload[key] = selectedOptions[key].label
        })
        const data = await generateBarcode(
          id || (currentProduct?.productAvailability?.id as string),
          payload,
        )
        toast.open('Штрихкод успешно сгенерирован!', 'success')
        onClose(e)
        window.open(data, '_blank')
        setSelectedOptions({})
      } catch (error) {
        onClose(e)
        toast.open(formatErrorForToast(error as any), 'danger')
      }
    }

    const isAllOptionsSelected =
      currentOptions &&
      (currentOptions.length === 0 ||
        currentOptions.every((option) => selectedOptions[option.id]))

    return (
      <ModalWindow onClose={onClose} isOpen={isOpen} maxWidth>
        <div className="flex  justify-between items-center py-3 w-[300px]">
          <PageTitle title="Печать бирки штрихкода" size="text-[20px]" />
          <CrossRemoveSVG size="24" onChange={onClose} />
        </div>
        <div className="flex flex-col gap-3">
          {currentOptions?.map((option) => (
            <div key={option.id}>
              <CustomSelect
                key={option.id}
                value={selectedOptions[option.id] || null}
                options={option.values
                  .filter((value) =>
                    ['enabled', 'active'].every((el) => el in value)
                      ? value.enabled && value.active
                      : true,
                  )
                  .map((value) => ({
                    value: value.id,
                    label: value.name,
                  }))}
                onChange={(selected) =>
                  handleSelectChange(option.id, selected as SelectedOption)
                }
                placeholder={option.name}
              />
            </div>
          ))}
        </div>

        <div className="flex items-center justify-center p-6 space-x-2 ">
          <Button intent="secondary" onClick={onClose} type="submit">
            {t('Cancel2')}
          </Button>
          {isAllOptionsSelected && (
            <Button intent="success" onClick={handleGenerateBarcode}>
              <SVGPrint color="white" />
              {t('print')}
            </Button>
          )}
        </div>
      </ModalWindow>
    )
  },
)
