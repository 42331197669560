import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from '~/app/store'
import { OrderData } from '~/shared/api/services/warehouses/types'
import { Button, PageTitle } from '~/shared/ui'
import { FilterSVG } from '~/shared/ui/SVG/SVGFilter'
import Spinner from '~/shared/ui/spinner'
import GoToTop from '../scroll-to-top'
import { IntentType } from './types'
import { NotFound } from '~/pages/not-found'

export const AssemblyByType = observer(() => {
  const { warehousesStore } = useStore()
  const { getAssembly, assembly, loaders } = warehousesStore
  const { type } = useParams<{ type: string }>()
  const [isOpen, setIsOpen] = useState(false)
  const dateRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})
  const navigate = useNavigate()
  useEffect(() => {
    if (type && type !== 'all') {
      getAssembly(type)
    }
  }, [type, getAssembly])

  if (type === 'all') {
    return <NotFound />
  }
  const toggleFilter = () => {
    setIsOpen((prev) => !prev)
  }

  useEffect(() => {
    const scrollToDate = (date: string) => {
      const targetRef = dateRefs.current[date]
      if (targetRef) {
        targetRef.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }

    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (target && target.id) {
        scrollToDate(target.id)
      }
      if (
        !target.closest('.filter-button') &&
        !target.closest('.filter-content')
      ) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  if (loaders.assembly) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Spinner />
      </div>
    )
  }

  const renderOrdersByType = () => {
    if (!assembly || !assembly.ordersData || !assembly.ordersData.dates) {
      return <Spinner />
    }
    const { dates } = assembly.ordersData

    // Если тип platform или retail — рендерим список без дат
    if (type === 'platform' || type === 'retail') {
      return (
        <div className="grid  grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-10 gap-5">
          {dates.length !== 0 ? (
            dates
              .reduce((acc, el) => [...acc, ...el.orders], [] as OrderData[])
              .map((order) => {
                return (
                  <Button
                    key={order.id}
                    badgeContent={order.badge}
                    intent={order.style as IntentType}
                    className="w-[140px] h-[48px] cursor-pointer"
                    onClick={() => {
                      warehousesStore.setSelectedOrder(order)
                      navigate(`/assembly/${order.id}/items`)
                    }}
                  >
                    {order.number}
                  </Button>
                )
              })
          ) : (
            <p>Заказы отсутвуют</p>
          )}
        </div>
      )
    }

    // Если тип не platform и не retail — рендерим с датами
    return (
      <div className="flex flex-col">
        {dates.length !== 0 ? (
          dates.map(({ date, orders }) => (
            <div
              key={date}
              ref={(el) => {
                if (el) dateRefs.current[date] = el
              }}
            >
              <p className="my-4 font-medium">{date}</p>
              <div className="flex flex-wrap gap-4">
                {orders.map((order: OrderData) => (
                  <Button
                    key={order.id}
                    badgeContent={order.badge}
                    intent={order.style as IntentType}
                    className="w-[140px] h-[48px] cursor-pointer"
                    onClick={() => {
                      warehousesStore.setSelectedOrder(order)
                      navigate(`/assembly/${order.id}/items`)
                    }}
                  >
                    {order.number}
                  </Button>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p>Заказы отсутвуют</p>
        )}
      </div>
    )
  }

  const renderDateFilters = () => {
    if (!assembly?.ordersData?.dates) return null

    return (
      <div className="w-[180px] max-h-[200px] bg-white absolute top-[108px] right-5 z-50 rounded-lg flex flex-col gap-2 py-1 px-2 overflow-y-auto">
        {assembly.ordersData.dates.map(({ date }) => (
          <div
            key={date}
            className="hover:bg-slate-100 rounded-lg py-2 px-2 relative group cursor-pointer"
            id={date}
          >
            {date}
            <span className="hidden group-hover:block absolute top-[13px] left-0 h-[15px] w-[3px] bg-blue-600 rounded-lg" />
          </div>
        ))}
      </div>
    )
  }

  return (
    <>
      <div className="relative">
        <div className="flex justify-between items-center mb-6">
          <div className="min-w-[200px]">
            {assembly ? (
              <PageTitle title={`Сборка ${type}`} size="text-[24px]" />
            ) : (
              <div className="h-[32px] w-full" />
            )}
          </div>

          {type !== 'platform' &&
          type !== 'retail' &&
          assembly?.ordersData?.dates?.length !== 0 ? (
            <div
              className="w-[32px] h-[32px] bg-white border border-gray-200 flex justify-center items-center rounded-lg cursor-pointer"
              onClick={toggleFilter}
            >
              <FilterSVG size="24px" color="#242424" />
            </div>
          ) : (
            <div className="w-[32px] h-[32px]" />
          )}
        </div>

        {renderOrdersByType()}
      </div>
      <div className="fixed bottom-8 left-7">
        <GoToTop />
      </div>
      {isOpen && renderDateFilters()}
    </>
  )
})
