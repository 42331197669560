import { FC } from 'react'

interface PropsCloseButton {
  size?: string
}

export const SVGCloseButton: FC<PropsCloseButton> = ({ size }) => {
  const fill = 'gray'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '16'}
      height={size || '16'}
      viewBox="0 0 16 16"
      fill="none"
      stroke={fill}
      strokeWidth="1"
    >
      <path
        d="M7.99999 8.70254L4.61792 12.0846C4.52562 12.1769 4.4096 12.2241 4.26986 12.2263C4.13012 12.2284 4.01197 12.1812 3.91539 12.0846C3.8188 11.988 3.77051 11.8709 3.77051 11.7333C3.77051 11.5957 3.8188 11.4786 3.91539 11.3821L7.29744 7.99999L3.91539 4.61792C3.82308 4.52562 3.77586 4.4096 3.77372 4.26986C3.77158 4.13012 3.8188 4.01197 3.91539 3.91539C4.01197 3.8188 4.12906 3.77051 4.26666 3.77051C4.40426 3.77051 4.52135 3.8188 4.61792 3.91539L7.99999 7.29744L11.3821 3.91539C11.4744 3.82308 11.5904 3.77586 11.7301 3.77372C11.8699 3.77158 11.988 3.8188 12.0846 3.91539C12.1812 4.01197 12.2295 4.12906 12.2295 4.26666C12.2295 4.40426 12.1812 4.52135 12.0846 4.61792L8.70254 7.99999L12.0846 11.3821C12.1769 11.4744 12.2241 11.5904 12.2263 11.7301C12.2284 11.8699 12.1812 11.988 12.0846 12.0846C11.988 12.1812 11.8709 12.2295 11.7333 12.2295C11.5957 12.2295 11.4786 12.1812 11.3821 12.0846L7.99999 8.70254Z"
        fill={fill}
      />
    </svg>
  )
}
