// Input
export enum LabelType {
  FLOATING = 'floating',
  CUSTOM = 'custom',
}

export enum InputType {
  PASSWORD = 'password',
  TEXT = 'text',
  EMAIL = 'email',
}

export enum InputStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

// Language Select

export const LANGUAGE_OPTIONS = [
  { value: 'ru', label: 'Русский' },
  { value: 'en', label: 'English' },
]
// Допустимое количество символов у строк
export const MAX_LENGTH = 255

// Id юр лица GREY SHOP
export const LEGAL_ENTITY_GREY_SHOP = '0716aa97-10ae-4932-9d69-b5b4b1cb2deb'

export const HOST_VIEW: { [key: string]: (string | number)[] } = {
  'smtp.google.com': ['Google', 587],
  'smtp.yandex.ru': ['Яндекс', 465],
  'smtp.jino.ru': ['Jino', 465],
  'smtp.mail.ru': ['Mail.ru', 465],
}
