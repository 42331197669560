/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from '~/app/store'
import { Button, Card, SVGEye } from '~/shared/ui'
import { SVGPrint } from '~/shared/ui/SVG/SVGPrint'
import { SVGDone } from '~/shared/ui/SVG/SVGDone'
import { DeliveryTabs } from '~/widgets/delivery/tabs-delivery'
import {
  LEGAL_ENTITY_GREY_SHOP,
  formatErrorForToast,
  useToast,
} from '~/shared/lib'
import Spinner from '~/shared/ui/spinner'
import { Skeleton } from '~/shared/ui/table/components/skeleton'

export const ShippingOrderById = observer(() => {
  const { deliveryStore, authStore } = useStore()
  const {
    getShippingOrderById,
    shippingOrderById,
    loaders,
    printBlanc,
    printBlankInfo,
  } = deliveryStore

  const { legalEntity } = authStore
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const toast = useToast()

  useEffect(() => {
    if (id) {
      getShippingOrderById(id)
    }
  }, [getShippingOrderById, id])

  const comments = shippingOrderById?.detail?.comments ?? []
  return (
    <>
      <div className="flex justify-between items-center pt-6">
        {loaders.shippingOrderById ? (
          <div className="w-[300px] h-[150px]">
            <Skeleton />
          </div>
        ) : (
          <div className="flex flex-col gap-3 pb-4 ">
            <p className="text-[24px] text-[#242424]">
              Коробка {shippingOrderById?.orderDetails?.box}. Проверка заказа{' '}
              {shippingOrderById?.orderDetails?.number}
            </p>
            <p className="text-[14px] text-[#707070]">
              Вес: {shippingOrderById?.orderDetails?.weight} гр. Объемный:
              {shippingOrderById?.orderDetails?.weightVolume} гр. Доставка:
              {shippingOrderById?.orderDetails?.shippingMethod}
            </p>
            <p className="text-[14px] text-[#707070]">
              Пользователь: {shippingOrderById?.orderDetails?.userName} собирал
              заказ
            </p>
          </div>
        )}

        <div className="flex h-[48px] gap-3">
          <Button onClick={() => navigate(`/orders/${id}/edit`)}>
            <SVGEye icon="visibility" color="white" />
            Просмотр заказа
          </Button>
          <Button
            disabled={loaders.printBlanc}
            onClick={async () => {
              try {
                if (printBlankInfo) {
                  const action =
                    legalEntity === LEGAL_ENTITY_GREY_SHOP
                      ? 'downloadBlank'
                      : 'printBlank'
                  const response = await printBlanc(
                    printBlankInfo,
                    id as string,
                    action,
                  )

                  if (
                    legalEntity === LEGAL_ENTITY_GREY_SHOP &&
                    response?.attachment.url
                  ) {
                    window.open(response?.attachment.url, '_blank')
                  }
                  if (action === 'printBlank') {
                    toast.open(response, 'success')
                  }
                }
              } catch (error: any) {
                toast.open(formatErrorForToast(error), 'danger')
              }
            }}
          >
            {loaders.printBlanc ? <Spinner /> : <SVGPrint color="white" />}
            Бланк
          </Button>

          <Button
            intent="success"
            disabled={
              !deliveryStore.itemsOrderById?.every((item) => item.checked) ||
              loaders.mandatoryMark
            }
            onClick={async () => {
              try {
                await deliveryStore.checkingItemsOrderById(id as string)
                toast.open('Заказ успешно отправлен!', 'success')
                navigate('/shipping/orders')
              } catch (error: any) {
                toast.open(formatErrorForToast(error), 'danger')
              }
            }}
          >
            {loaders.mandatoryMark ? (
              <Spinner />
            ) : (
              <SVGDone color="white" size="30" />
            )}
            Проверить
          </Button>
        </div>
      </div>
      <div>
        {loaders.shippingOrderById ? (
          <Spinner />
        ) : comments.length > 0 ? (
          <Card>
            <span className="text-[24px] text-[#242424]">
              Комментарии к заказу
            </span>
            {comments.map((el) => (
              <div
                key={el.id}
                className="flex flex-col p-2 mt-2 bg-[#D1ECF1] rounded-lg"
              >
                <span>{el.message}</span>
                <span className="text-[12px] text-[#707070]">
                  {new Date(el.created_at).toLocaleString('ru-RU')}
                </span>
              </div>
            ))}
          </Card>
        ) : null}

        <DeliveryTabs />
      </div>
    </>
  )
})
