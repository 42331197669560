import React, { FC } from 'react'
import css from 'classnames'
import { TabsProps } from './types'
import { tabs } from './config'

const TabsContent: FC<TabsProps> = ({
  className,
  items = [],
  activeTab,
  tabsType,
}) => {
  return (
    <div
      className={css(
        { 'border-none': tabsType === 'contained' },
        tabs({ className }),
      )}
    >
      {items.map((item, i) => {
        return i === activeTab ? (
          <React.Fragment key={`${item.id}${activeTab}`}>
            {item.element}
          </React.Fragment>
        ) : null
      })}
    </div>
  )
}

export default TabsContent
