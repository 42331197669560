import css from 'classnames'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'usehooks-ts'
import { motion } from 'framer-motion'
import { NavUser, Navigation } from './components'
import { headerAnimation } from './model'
import { SVGCloseButton, SVGMenu } from '~/shared/ui'
import { SVGLogo } from '~/shared/ui/SVG/SVGLogo'

export const Header = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { pathname } = useLocation()
  const matches = useMediaQuery('(max-width: 768px)')

  const toggleMenu = () => setIsOpen((prev) => !prev)

  useEffect(() => {
    if (matches) {
      setIsOpen(false)
    }
  }, [pathname, matches])

  useEffect(() => {
    if (isOpen) {
      const { scrollY } = window
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollY}px`
      document.body.style.left = '0'
      document.body.style.right = '0'
      document.body.style.overflow = 'hidden'
    } else {
      const scrollY = Math.abs(parseInt(document.body.style.top || '0', 10))
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.overflow = ''
      window.scrollTo(0, scrollY) // Вернуть на место после закрытия
    }

    return () => {
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.overflow = ''
    }
  }, [isOpen])

  return (
    <header className="bg-white px-4">
      <motion.div
        initial="closed"
        animate={['closed', 'open']}
        variants={headerAnimation}
        className="flex items-center font-medium justify-center"
      >
        <div className="text-lg font-bold z-[999] md:w-auto w-full flex justify-between items-center">
          <Link to="/" className="p-0">
            <SVGLogo width="42" height="42" />
          </Link>

          <div
            className="md:hidden flex items-center justify-center"
            onClick={toggleMenu}
          >
            {isOpen ? <SVGCloseButton size="34" /> : <SVGMenu size="34" />}
          </div>
        </div>
        <nav className="mr-auto ml-5 md:flex hidden">
          <Navigation />
        </nav>

        <div className="ml-5 md:block hidden">
          <NavUser />
        </div>

        <nav
          className={css(
            'md:hidden bg-white fixed inset-0 pt-20  p-2 duration-500 z-50 flex flex-col justify-between transition-transform',
            { 'translate-x-0': isOpen, '-translate-x-full': !isOpen },
          )}
        >
          <div className="flex flex-col flex-grow">
            <Navigation className="flex-col mb-4" />
          </div>
          <div className="p-4">
            <NavUser />
          </div>
        </nav>
      </motion.div>
    </header>
  )
}
