import { observer } from 'mobx-react-lite'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '~/app/store'
import { Card, Switcher } from '~/shared/ui'
import lan from '~/app/locale/languages/en.json'
import { Input } from '~/shared/ui/input'

export const PrintedForm = observer(() => {
  const { deliveryStore } = useStore()
  const { t } = useTranslation()

  const { printBlankInfo, setFieldValue } = deliveryStore

  type TranslationKey = keyof (typeof lan)['translation']

  const mapFieldToTranslationKey = (fieldName: string): TranslationKey => {
    const translationMap: Record<string, TranslationKey> = {
      username: 'name',
      postcode: 'postal code',
      city: 'city',
      region: 'Region',
      country: 'ACountry',
      address: 'address',
      phone: 'telephone',
      email: 'email',
      company: 'company',
    }
    return translationMap[fieldName] || (fieldName as TranslationKey)
  }

  return (
    <div className="flex justify-between gap-3">
      <Card className="w-[50%] p-6">
        <div className=" flex flex-col gap-3  ">
          {printBlankInfo?.fields.map((input) => (
            <div key={input.name}>
              <Input
                className="rounded-xl"
                labelType="floating"
                label={
                  <>
                    {t(mapFieldToTranslationKey(input.name) as TranslationKey)}
                    {input.required && (
                      <span className="text-red-500 text-[10px] ml-1">*</span>
                    )}
                  </>
                }
                name={input.name}
                value={input.value as string}
                disabled={input.disabled}
                intent={input.disabled ? 'disabled' : undefined}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFieldValue(input.name, e.target.value)
                }
              />
            </div>
          ))}
        </div>
      </Card>

      <Card className="w-[50%] h-[300px] flex flex-col gap-4  p-6">
        {printBlankInfo?.additionalFields.map((el) => (
          <div key={el.name}>
            {el.name === 'ddp' ? (
              <div key={el.name} className="flex flex-col gap-2">
                <p className="text-[12.5px] font-medium">{el.title}</p>
                <Switcher
                  label="Активна"
                  defaultValue={!!el.value}
                  onChange={(value: boolean) => setFieldValue(el.name, value)}
                />
              </div>
            ) : (
              <div className="flex flex-col gap-3">
                <Input
                  id={el.name}
                  className="rounded-xl"
                  labelType="floating"
                  label={
                    <>
                      {t(mapFieldToTranslationKey(el.name) as TranslationKey)}
                      {el.required && (
                        <span className="text-red-500 text-[10px] ml-1">*</span>
                      )}
                    </>
                  }
                  name={el.name}
                  value={el.value as string}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(el.name, e.target.value)
                  }
                />
                <p className="text-[12px]">{el.help}</p>
              </div>
            )}
          </div>
        ))}
      </Card>
    </div>
  )
})
