interface PropsSvgMoney {
  color?: string
}

export const SVGMoney = ({ color }: PropsSvgMoney) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs />
      <path
        id="currency_ruble"
        d="M7.99 20.49C7.78 20.49 7.6 20.42 7.46 20.28C7.32 20.14 7.25 19.96 7.25 19.74L7.25 17.59L6 17.59C5.78 17.59 5.6 17.52 5.46 17.38C5.32 17.23 5.25 17.05 5.25 16.84C5.25 16.63 5.32 16.45 5.46 16.31C5.6 16.16 5.78 16.09 6 16.09L7.25 16.09L7.25 13.73L6 13.73C5.78 13.73 5.6 13.65 5.46 13.51C5.32 13.37 5.25 13.19 5.25 12.98C5.25 12.76 5.32 12.58 5.46 12.44C5.6 12.3 5.78 12.23 6 12.23L7.25 12.23L7.25 4.4C7.25 4.14 7.33 3.93 7.5 3.75C7.68 3.58 7.89 3.5 8.15 3.5L13.63 3.5C15.09 3.5 16.31 3.98 17.28 4.96C18.26 5.93 18.75 7.15 18.75 8.61C18.75 10.07 18.26 11.29 17.28 12.27C16.31 13.24 15.09 13.73 13.63 13.73L8.75 13.73L8.75 16.09L12 16.09C12.21 16.09 12.39 16.16 12.53 16.31C12.67 16.45 12.75 16.63 12.75 16.84C12.75 17.05 12.67 17.23 12.53 17.38C12.39 17.52 12.21 17.59 12 17.59L8.75 17.59L8.75 19.74C8.75 19.96 8.67 20.14 8.53 20.28C8.39 20.42 8.21 20.49 7.99 20.49ZM8.75 12.23L13.63 12.23C14.67 12.23 15.53 11.88 16.22 11.2C16.9 10.51 17.25 9.65 17.25 8.61C17.25 7.57 16.9 6.71 16.22 6.02C15.53 5.34 14.67 5 13.63 5L8.75 5L8.75 12.23Z"
        fill={color}
        stroke="#707070"
        strokeWidth="0.5"
      />
    </svg>
  )
}
