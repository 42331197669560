/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import {
  Brand,
  Category,
  CreateFeatureModel,
  CreateMaterialModel,
  EditableProductModel,
  Feature,
  Item,
  Manufacturer,
  Material,
  Option,
  OptionDetails,
  Options,
  Product,
  ProductsService,
  ShippingModel,
} from '~/shared/api'
import { handleAxiosError, mapDataForSelect, parseQuery } from '~/shared/lib'
import { PRODUCTS_STATUSES } from '../lib'
import { FilterName, Status } from './types'
import { globalStore } from '~/shared/store/global-store'

export class ProductsModel {
  manufacturer: Manufacturer[] = []

  manufacturerProductAvailabilityId: Manufacturer[] = []

  options: Option[] = []

  filtersBySelect = globalStore.filtersBySelect

  stores = globalStore.stores

  categories = globalStore.filtersBySelect.categories

  statuses: Status[] = PRODUCTS_STATUSES

  shipping: ShippingModel[] = []

  materials: Material[] = []

  materialsProductAvailabilityId: Material[] = []

  featuresProductAvailabilityId: Feature[] = []

  features: Feature[] = []

  brands: Brand[] = []

  brandsProductAvailabilityId: Brand[] = []

  requiredLocalizations: string[] | null = null

  items: Item[] = []

  itemsLoading = false

  optionsItem: Options | null = null

  optionDetails: OptionDetails[] = []

  // Settings for infinite scroll
  requestSettings = {
    limit: 20,
    offset: 0,
    isAllLoaded: false,
  }

  products: Product[] = []

  currentProduct: EditableProductModel | null = null

  error: null | string = null

  loadedAllFilters = false

  categoriesLoading = false

  manufacturerLoading = false

  storesLoading = false

  dataBarcode = ''

  optionsLoading = false

  productsLoading = false

  barcodeLoading = false

  productsError = false

  loadingMoreProducts = false

  isCreatingProduct = false

  isAddingMaterial = false

  isAddingFeature = false

  isGettingProduct = false

  isUpdatingProduct = false

  productsSubContentOpens: string[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setIdsSubContentOpens = (id: string) => {
    this.productsSubContentOpens = this.productsSubContentOpens.includes(id)
      ? this.productsSubContentOpens.filter((e) => e !== id)
      : [...this.productsSubContentOpens, id]
  }

  getFilters = async () => {
    return Promise.all([this.getManufacturers(), this.getOptions()])
      .then((res) => {
        runInAction(() => {
          const [manufacturer, options] = res
          this.manufacturer = manufacturer
          this.options = options
        })
      })
      .finally(() => {
        runInAction(() => {
          this.loadedAllFilters = true
        })
      })
  }

  getShipping = async () => {
    try {
      const { data } = await ProductsService.getShipping()

      runInAction(() => {
        this.shipping = data
      })

      return data
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }

    return []
  }

  getManufacturers = async (q?: string) => {
    this.manufacturerLoading = true

    try {
      const params: Record<string, number | string> = {
        limit: 20,
        offset: 0,
      }
      if (q?.trim()) {
        params.q = q
      }
      const { data } = await ProductsService.getManufacturers({
        params,
      })

      runInAction(() => {
        this.manufacturer = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.manufacturerLoading = false
      })
    }

    return []
  }

  getOptions = async (productAvailabilityId?: string) => {
    this.optionsLoading = true

    try {
      const { data } = await ProductsService.getOptions({
        params: {
          productAvailabilityId,
        },
      })

      runInAction(() => {
        this.options = data.map((item) => ({
          ...item,
          active: false,
          enabled: false,
        }))
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.optionsLoading = false
      })
    }

    return []
  }

  getProducts = async (paramsFilter?: string) => {
    this.productsLoading = true
    this.productsError = false

    this.currentProduct = null

    const params = ProductsModel.getParamsFromQueryString(paramsFilter || '')

    try {
      const { data } = await ProductsService.getProducts({
        params,
      })
      runInAction(() => {
        this.products = data
        this.requestSettings = {
          ...this.requestSettings,
          isAllLoaded: data.length < this.requestSettings.limit,
        }
        this.productsSubContentOpens = []
      })

      return data
    } catch (error) {
      this.productsError = true
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.productsLoading = false
      })
    }
  }

  getItems = async (id: string) => {
    this.itemsLoading = true
    try {
      const { data } = await ProductsService.getItems(id)
      runInAction(() => {
        this.items = data.items
        this.optionsItem = data.options
      })

      return data
    } catch (error) {
      this.productsError = true
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.itemsLoading = false
      })
    }
  }

  loadProducts = async (paramsFilter?: string) => {
    if (this.requestSettings.isAllLoaded || this.productsError) return

    this.loadingMoreProducts = true

    const params = ProductsModel.getParamsFromQueryString(paramsFilter || '')

    try {
      const { data } = await ProductsService.getProducts({
        params: {
          ...params,
          limit: this.requestSettings.limit,
          offset: this.requestSettings.offset,
        },
      })

      runInAction(() => {
        this.products = [...this.products, ...data]

        this.requestSettings = {
          ...this.requestSettings,
          isAllLoaded: data.length < this.requestSettings.limit,
          offset: this.requestSettings.offset + this.requestSettings.limit,
        }
        this.loadingMoreProducts = false
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.loadingMoreProducts = false
      })
    }
  }

  getMaterials = async (q?: string) => {
    try {
      const params: Record<string, number | string> = {
        limit: 20,
        offset: 0,
      }
      if (q?.trim()) {
        params.q = q
      }
      const { data } = await ProductsService.getMaterials({
        params,
      })

      runInAction(() => {
        this.materials = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getMaterialsProductAvailabilityId = async (
    productAvailabilityId?: string,
  ) => {
    try {
      const { data } = await ProductsService.getMaterials({
        params: {
          limit: 20,
          offset: 0,
          productAvailabilityId,
        },
      })

      runInAction(() => {
        this.materialsProductAvailabilityId = data
      })
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  createProduct = async (product: unknown) => {
    this.isCreatingProduct = true

    try {
      const { data } = await ProductsService.createProduct(product)

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data
      }
    } finally {
      this.currentProduct = null
      this.isCreatingProduct = false
    }
  }

  addMaterial = async (res: CreateMaterialModel) => {
    runInAction(() => {
      this.isAddingMaterial = true
    })

    try {
      const { data } = await ProductsService.addMaterial(res)
      runInAction(() => {
        this.materials = [data, ...this.materials]
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.isAddingMaterial = false
      })
    }
  }

  generateBarcode = async (id: string, options: Record<string, string>) => {
    runInAction(() => {
      this.barcodeLoading = true
    })

    try {
      const payload = { options }
      const { data } = await ProductsService.generateBarcode(id, payload)

      runInAction(() => {
        this.dataBarcode = data
      })

      return data
    } catch (error) {
      const errorMessage = handleAxiosError(error)
      throw errorMessage as any
    } finally {
      runInAction(() => {
        this.barcodeLoading = false
      })
    }
  }

  addFeature = async (res: CreateFeatureModel) => {
    runInAction(() => {
      this.isAddingFeature = true
    })

    try {
      const { data } = await ProductsService.addFeature(res)

      runInAction(() => {
        this.features = [data, ...this.features]
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.isAddingFeature = false
      })
    }
  }

  getFeatures = async (q?: string) => {
    try {
      const params: Record<string, number | string> = {
        limit: 20,
        offset: 0,
      }
      if (q?.trim()) {
        params.q = q
      }
      const { data } = await ProductsService.getFeatures({
        params,
      })
      runInAction(() => {
        this.features = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getFeaturesProductAvailabilityId = async (productAvailabilityId: string) => {
    try {
      const { data } = await ProductsService.getFeatures({
        params: {
          limit: 20,
          offset: 0,
          productAvailabilityId,
        },
      })

      runInAction(() => {
        this.featuresProductAvailabilityId = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getManufacturesProductAvailabilityId = async (
    productAvailabilityId: string,
  ) => {
    try {
      const { data } = await ProductsService.getManufacturers({
        params: {
          limit: 20,
          offset: 0,
          productAvailabilityId,
        },
      })

      runInAction(() => {
        this.manufacturerProductAvailabilityId = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getRequiredLocalization = async () => {
    try {
      const { localizations } = await ProductsService.getRequiredLocalization()
      runInAction(() => {
        this.requiredLocalizations = localizations
      })
      return localizations
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getBrandsProductAvailabilityId = async (productAvailabilityId: string) => {
    try {
      const { data } = await ProductsService.getBrands({
        params: {
          limit: 20,
          offset: 0,
          productAvailabilityId,
        },
      })

      runInAction(() => {
        this.brandsProductAvailabilityId = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  getBrands = async (q?: string) => {
    try {
      const params: Record<string, number | string> = {
        limit: 20,
        offset: 0,
      }
      if (q?.trim()) {
        params.q = q
      }
      const { data } = await ProductsService.getBrands({
        params,
      })

      runInAction(() => {
        this.brands = data
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }
  }

  updateProduct = async (id: string, res: unknown) => {
    runInAction(() => {
      this.isUpdatingProduct = true
    })

    try {
      await ProductsService.updateProduct(id, res)
    } catch (error) {
      if (error instanceof AxiosError) {
        throw error.response?.data
      }
    } finally {
      runInAction(() => {
        this.isUpdatingProduct = false
      })
    }
  }

  getProductById = async (
    id: string,
    cb: (data: EditableProductModel) => void,
  ) => {
    runInAction(() => {
      this.isGettingProduct = true
    })

    try {
      await Promise.all([
        this.getManufacturesProductAvailabilityId(id),
        this.getBrandsProductAvailabilityId(id),
        this.getFeaturesProductAvailabilityId(id),
        this.getMaterialsProductAvailabilityId(id),
      ])
      const { data } = await ProductsService.getProductById(id)
      let copy = { ...data }
      if (
        data.productAvailability.price.toString().includes('00') &&
        data.productAvailability.price_original.toString().includes('00')
      ) {
        copy = {
          ...copy,
          productAvailability: {
            ...copy.productAvailability,
            price_original: copy.productAvailability.price_original / 100,
            price: copy.productAvailability.price / 100,
          },
        }
      }
      runInAction(() => {
        cb(copy)
        this.currentProduct = copy
        // for barcode modal
        this.optionDetails = copy.options.filter((option) => !option.isHidden)
      })
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.isGettingProduct = false
      })
    }
  }

  getFilterByName = async (name: FilterName, value: string) => {
    try {
      const { data } = await ProductsService.getFilterByName(name, {
        params: {
          ...(value.length > 0 && { q: value, limit: 20, offset: 0 }),
        },
      })

      runInAction(() => {
        this.setFilter(name, data)
      })

      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    }

    return []
  }

  private setFilter(name: FilterName, data: unknown) {
    runInAction(() => {
      if (name === 'manufacturer') {
        this.manufacturer = data as Manufacturer[]
      }

      if (name === 'options') {
        this.options = data as Option[]
      }

      if (name === 'categories') {
        this.categories = data as Category[]
      }
    })
  }

  // Mappers
  get getMappedSelectFilters() {
    return {
      categories: this.categories
        ? mapDataForSelect(this.categories, 'id', 'name')
        : [],
      manufacturers: mapDataForSelect(
        [...this.manufacturer, ...this.manufacturerProductAvailabilityId],
        'id',
        'name',
      ),
      options: mapDataForSelect(this.options, 'id', 'name'),
      stores: mapDataForSelect(this.stores, 'id', 'name'),
      materials: mapDataForSelect(
        [...this.materials, ...this.materialsProductAvailabilityId],
        'id',
        'description',
      ),
      materialsRu: mapDataForSelect(
        this.materials.map((item) => item.localizations[0]),
        'product_material_id',
        'description',
      ),
      featuresRu: mapDataForSelect(
        this.features.map((item) => item.localizations[0]),
        'product_feature_id',
        'title',
      ),
      features: mapDataForSelect(
        [...this.features, ...this.featuresProductAvailabilityId],
        'id',
        'title',
      ),
      brands: mapDataForSelect(
        [...this.brands, ...this.brandsProductAvailabilityId],
        'id',
        'name',
      ),
      shipping: mapDataForSelect(this.shipping, 'code', 'name'),
    }
  }

  static getParamsFromQueryString(value?: string) {
    if (value?.length === 0) return {}

    const { q, sku, vendorCode, ...res } = parseQuery(value || '')

    const productName = q && q.join('').length > 0 ? { q: q.join('') } : {}

    const productSku =
      sku && sku.join('').length > 0 ? { sku: sku.join('') } : {}

    const productVendorCode =
      vendorCode && vendorCode.join('').length > 0
        ? { vendorCode: vendorCode.join('') }
        : {}

    return {
      ...res,
      ...productName,
      ...productVendorCode,
      ...productSku,
    }
  }
}

export const productCreateImageType = [
  { id: 1, type: 'image_cover', label: 'Обложка' },
  { id: 2, type: 'image_hover', label: 'При наведении' },
]
