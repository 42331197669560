import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStore } from '~/app/store'
import { Card, Switcher, TableContainer } from '~/shared/ui'

import { DELIVERY_TABLE_CONFIG } from './lib/configTable'
import { Input } from '~/shared/ui/input'
import { ModalMandatorymarkField } from './ModalMandatorymarkField'
import { useToast } from '~/shared/lib'

export const OrdersItemsTable = observer(() => {
  const { deliveryStore } = useStore()
  const {
    itemsOrderById,
    loaders,
    getProductByBarcode,
    setBarcode,
    barcode,
    modalMandatoryMarkField,
    setOrderItems,
  } = deliveryStore
  const { t } = useTranslation()
  const { id } = useParams()
  const barcodeInputRef = useRef<HTMLInputElement>(null)
  const [barcodeModeDelivery, setBarcodeModeDelivery] = useState(false)
  const toast = useToast()

  useEffect(() => {
    if (barcodeModeDelivery) {
      setTimeout(() => {
        if (barcodeInputRef.current) {
          barcodeInputRef.current.focus()
        }
      }, 0)
    }
  }, [barcodeModeDelivery])

  return (
    <Card>
      <div className=" flex flex-col gap-3 w-[50%]  p-4">
        <div className=" py-3 w-[50%] flex ">
          <Switcher
            defaultValue={barcodeModeDelivery}
            onChange={(value) => {
              setBarcodeModeDelivery(value)
            }}
          />
          <p> {t('searchByBarcode')}</p>
        </div>
        {barcodeModeDelivery && (
          <form
            onSubmit={async (e) => {
              e.preventDefault()
              if (barcode) {
                try {
                  const data = await getProductByBarcode(barcode)
                  const nextItem = itemsOrderById.find(
                    (item) =>
                      item.productVariationId === data.id && !item.checked,
                  )
                  const findItemInOrder = itemsOrderById.find(
                    (item) => item.productVariationId === data.id,
                  )
                  if (nextItem) {
                    toast.open('Товар успешно найден по BARCODE!', 'success')
                    if (nextItem.mandatoryMarkField) {
                      deliveryStore.modalMandatoryMarkField = {
                        open: true,
                        id: nextItem.id,
                        mandatoryMarkField: nextItem.mandatoryMarkField,
                      }
                    } else {
                      setOrderItems(nextItem.id, 'checked', true)
                    }
                  } else if (findItemInOrder) {
                    toast.open(
                      'Все товары с этим штрихкодом уже отмечены!',
                      'danger',
                    )
                  } else {
                    toast.open(
                      'Такого товара не существует в этом заказе!',
                      'danger',
                    )
                  }
                } catch {
                  toast.open('Товар не найден!', 'danger')
                } finally {
                  deliveryStore.resetBarcode()
                  if (barcodeInputRef.current) {
                    barcodeInputRef.current.focus()
                  }
                }
              }
            }}
          >
            <Input
              labelType="floating"
              label="Штрихкод"
              className="rounded-lg"
              id="barcode"
              autoFocus
              value={barcode}
              ref={barcodeInputRef}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setBarcode(e.target.value)
              }}
            />
          </form>
        )}
      </div>
      <div>
        <TableContainer
          fixedWidth
          isLoading={loaders.shippingOrderById || loaders.barcode}
          columns={DELIVERY_TABLE_CONFIG}
          data={itemsOrderById}
          onRowClick={(item) => {
            if (item.checked) {
              setOrderItems(item.id, 'checked', false)
              setOrderItems(item.id, 'markValue', '')
              return
            }

            if (item.mandatoryMarkField) {
              deliveryStore.modalMandatoryMarkField = {
                open: true,
                id: item.id,
                mandatoryMarkField: item.mandatoryMarkField,
              }
            } else {
              setOrderItems(item.id, 'checked', !item.checked)
            }
          }}
          className="bg-gray-300"
        />
      </div>
      <ModalMandatorymarkField
        orderId={id}
        isOpen={modalMandatoryMarkField.open}
        id={modalMandatoryMarkField.id}
        originalData={modalMandatoryMarkField.mandatoryMarkField}
        onClose={(e) => {
          e.preventDefault()
          deliveryStore.modalMandatoryMarkField = {
            open: false,
            id: '',
            mandatoryMarkField: '',
          }
          deliveryStore.inputMandatoryMark = ''
        }}
      />
    </Card>
  )
})
