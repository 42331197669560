import { ColumnDef } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { Product } from '~/shared/api'
import { Status } from '../model/types'
import { SVGNOtFoto } from '~/shared/ui/SVG/SVGNotFoto'
import { ArrowToDownTable } from '~/shared/ui/table/ui'

export const PRODUCTS_STORAGE_FILTER_KEY = 'productsFilters'
export const PRODUCTS_STORAGE_FILTER_PARAMS_STR = 'productsFiltersQueryString'

export const PRODUCTS_STATUSES: Status[] = [
  { label: 'Активен', value: 'true' },
  { label: 'Отключен', value: 'false' },
]

export const PRODUCTS_TABLE_CONFIG: ColumnDef<Product>[] = [
  {
    header: '',
    accessorKey: 'spoiler',

    cell: (row) => {
      return (
        <ArrowToDownTable
          id={row.cell.row.original.id}
          storeName="productsStore"
        />
      )
    },
    maxSize: 5,
  },
  {
    header: 'image',
    accessorKey: 'image',

    cell: (row) => {
      const { original } = row.cell.row

      const imageSrc = original?.images?.first?.original

      return (
        <div className="w-16 h-16 rounded shadow">
          {imageSrc ? (
            <Link to={`/products/${original.id}/edit`}>
              <img
                className="w-full h-full rounded"
                src={imageSrc}
                alt="Product Thumbnail"
              />
            </Link>
          ) : (
            <div className="w-full h-full rounded">
              <SVGNOtFoto size="64" />
            </div>
          )}
        </div>
      )
    },
    maxSize: 50,
  },
  {
    header: 'name',
    accessorKey: 'name',

    cell: (row) => {
      const { original } = row.cell.row

      return (
        <Link
          to={`/products/${original.id}/edit`}
          className="truncate w-full block"
        >
          {original?.name}
        </Link>
      )
    },
    maxSize: 150,
  },

  {
    header: 'price',
    accessorKey: 'priceString',
    cell: (row) => {
      return <div> {row.cell.row.original.priceString}</div>
    },
    maxSize: 150,
  },
  {
    header: 'manufacturer',
    accessorKey: 'manufacturer',

    cell: (row) => {
      return <div>{row.cell.row.original?.manufacturer?.name}</div>
    },
    maxSize: 150,
  },
]
