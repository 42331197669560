import { ChangeEvent, FC, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { useTranslation } from 'react-i18next'
import MDEditor from '@uiw/react-md-editor'
import { Props } from './types'
import { TagInput, TextArea, ToolTip } from '~/shared/ui'
import { useStore } from '~/app/store'
import { Input } from '~/shared/ui/input'

export const CreateCategoryForm: FC<Props> = observer(({ locale }) => {
  const { t } = useTranslation()
  const { categoriesStore } = useStore()
  const {
    setCreateCategoryData,
    createCategoryData,
    createCategoryDataValidation,
    setIsErrorCategoryName,
    setLocale,
  } = categoriesStore

  useEffect(() => {
    setLocale(locale)
  }, [setLocale, locale])

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { target } = e

    setCreateCategoryData(
      {
        [locale]: {
          ...createCategoryData.localization[locale],
          [target.name]: target.value,
        },
      },
      'localization',
    )
  }

  const handleTags = (value: string[]) => {
    setCreateCategoryData({ seoKeywords: value.join(', ') }, 'localization')
  }

  const handleBlur = () => {
    if (createCategoryDataValidation.isErrorCategoryName) {
      setIsErrorCategoryName(false)
    }
  }

  const handleChangeMD = (e: string | undefined, name: 'description') => {
    setCreateCategoryData(
      {
        [locale]: {
          ...createCategoryData.localization[locale],
          [name]: e,
        },
      },
      'localization',
    )
  }

  const defaultKeywords = useMemo(() => {
    const arrOfWords =
      createCategoryData.localization[locale]?.seoKeywords?.split(', ')

    return arrOfWords?.filter(Boolean)
  }, [createCategoryData.localization, locale])

  return (
    <form className="rounded-lg">
      <div className="flex items-center gap-2 mb-2">
        <h2 className="text-lg text-title-color font-medium">{t('name2')}</h2>

        <ToolTip
          tooltipText={t('defaultToolTipText')}
          tooltipTitle={t('defaultToolTipTitle')}
        />
      </div>

      <div className="flex flex-col gap-3">
        <Input
          label={t('categoryName')}
          labelType="floating"
          name="name"
          id="name"
          onBlur={handleBlur}
          value={createCategoryData.localization[locale]?.name || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          status={
            createCategoryDataValidation.isErrorCategoryName ? 'error' : null
          }
          helperText={
            createCategoryDataValidation.isErrorCategoryName
              ? t('requiredField')
              : ''
          }
        />

        <MDEditor
          value={createCategoryData.localization[locale]?.description || ''}
          onChange={(value) => handleChangeMD(value, 'description')}
        />
      </div>

      <hr className="my-4" />

      <div className="flex items-center gap-2 mb-2">
        <h3 className="text-lg text-title-color font-medium">Seo</h3>
        <ToolTip
          tooltipText={t('defaultToolTipText')}
          tooltipTitle={t('defaultToolTipTitle')}
        />
      </div>

      <div className="flex flex-col gap-3">
        <Input
          label={t('name2')}
          labelType="floating"
          name="seoTitle"
          id="seoTitle"
          value={createCategoryData.localization[locale]?.seoTitle || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          renderIconEnd={
            <ToolTip
              tooltipText={t('defaultToolTipText')}
              tooltipTitle={t('defaultToolTipTitle')}
            />
          }
        />
        {createCategoryData.localization[locale]?.seoDescription.length > 50 ? (
          <TextArea
            label={t('description')}
            name="seoDescription"
            id="seoDescription"
            onChange={handleChange}
            value={createCategoryData.localization[locale]?.seoDescription}
          />
        ) : (
          <Input
            label={t('description')}
            labelType="floating"
            id="seoDescription"
            name="seoDescription"
            value={
              createCategoryData.localization[locale]?.seoDescription || ''
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            renderIconEnd={
              <ToolTip
                tooltipText={t('defaultToolTipText')}
                tooltipTitle={t('defaultToolTipTitle')}
              />
            }
          />
        )}
        <TagInput
          defaultData={defaultKeywords}
          placeholder={t('keyWords')}
          onChange={handleTags}
          renderIconEnd={
            <ToolTip
              tooltipText={t('defaultToolTipText')}
              tooltipTitle={t('defaultToolTipTitle')}
            />
          }
        />
      </div>
    </form>
  )
})
