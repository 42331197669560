import React from 'react'

interface SVGCalendarProps {
  className?: string
}

export const SVGCalendar: React.FC<SVGCalendarProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        d="M9.19993 16.0576C8.55763 16.0576 8.01243 15.8336 7.56435 15.3855C7.11628 14.9375 6.89225 14.3923 6.89225 13.75C6.89225 13.1077 7.11628 12.5625 7.56435 12.1144C8.01243 11.6663 8.55763 11.4423 9.19993 11.4423C9.84223 11.4423 10.3874 11.6663 10.8355 12.1144C11.2836 12.5625 11.5076 13.1077 11.5076 13.75C11.5076 14.3923 11.2836 14.9375 10.8355 15.3855C10.3874 15.8336 9.84223 16.0576 9.19993 16.0576ZM5.50765 21.5C5.00252 21.5 4.57495 21.325 4.22495 20.975C3.87495 20.625 3.69995 20.1974 3.69995 19.6923V6.30772C3.69995 5.80259 3.87495 5.37502 4.22495 5.02502C4.57495 4.67502 5.00252 4.50002 5.50765 4.50002H6.89228V3.15384C6.89228 2.93463 6.96568 2.75162 7.11248 2.60482C7.25926 2.45804 7.44227 2.38464 7.6615 2.38464C7.88072 2.38464 8.06373 2.45804 8.21053 2.60482C8.35731 2.75162 8.4307 2.93463 8.4307 3.15384V4.50002H16.0076V3.13462C16.0076 2.9218 16.0794 2.7436 16.223 2.60002C16.3666 2.45643 16.5448 2.38464 16.7576 2.38464C16.9704 2.38464 17.1486 2.45643 17.2922 2.60002C17.4358 2.7436 17.5076 2.9218 17.5076 3.13462V4.50002H18.8922C19.3973 4.50002 19.8249 4.67502 20.1749 5.02502C20.5249 5.37502 20.6999 5.80259 20.6999 6.30772V19.6923C20.6999 20.1974 20.5249 20.625 20.1749 20.975C19.8249 21.325 19.3973 21.5 18.8922 21.5H5.50765ZM5.50765 20H18.8922C18.9691 20 19.0397 19.9679 19.1038 19.9038C19.1679 19.8397 19.1999 19.7692 19.1999 19.6923V10.3077H5.19993V19.6923C5.19993 19.7692 5.23198 19.8397 5.29608 19.9038C5.36019 19.9679 5.43072 20 5.50765 20ZM5.19993 8.80774H19.1999V6.30772C19.1999 6.23079 19.1679 6.16026 19.1038 6.09614C19.0397 6.03204 18.9691 5.99999 18.8922 5.99999H5.50765C5.43072 5.99999 5.36019 6.03204 5.29608 6.09614C5.23198 6.16026 5.19993 6.23079 5.19993 6.30772V8.80774Z"
        fill="#707070"
      />
    </svg>
  )
}
