interface PropsReplay {
  color?: string
}

export const SVGReplay = ({ color = '"#c7c7c7"' }: PropsReplay) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.1231 20.2481C8.47053 19.8288 7.12021 18.9535 6.07215 17.6221C5.02406 16.2907 4.50002 14.75 4.50002 13C4.50002 12.1013 4.65676 11.242 4.97022 10.4221C5.28369 9.60226 5.72247 8.85002 6.28657 8.1654C6.42502 8.01028 6.59232 7.93016 6.78847 7.92502C6.98462 7.91989 7.16539 8.00002 7.33077 8.1654C7.46924 8.30387 7.54103 8.47278 7.54615 8.67213C7.55128 8.87148 7.48461 9.05897 7.34615 9.2346C6.9077 9.75768 6.57372 10.3404 6.34422 10.9827C6.11474 11.625 6 12.2974 6 13C6 14.3757 6.41506 15.5991 7.2452 16.6702C8.07533 17.7414 9.13783 18.4526 10.4327 18.8039C10.5981 18.8513 10.734 18.9442 10.8404 19.0827C10.9468 19.2211 11 19.3731 11 19.5385C11 19.7884 10.9138 19.9852 10.7413 20.1288C10.5689 20.2724 10.3628 20.3122 10.1231 20.2481ZM13.8769 20.2673C13.6372 20.3314 13.4311 20.2891 13.2586 20.1404C13.0862 19.9916 13 19.7923 13 19.5423C13 19.3872 13.0532 19.2404 13.1596 19.1019C13.266 18.9635 13.4019 18.8673 13.5673 18.8135C14.8558 18.4199 15.9167 17.6949 16.75 16.6385C17.5833 15.5821 18 14.3692 18 13C18 11.3333 17.4167 9.91667 16.25 8.75C15.0833 7.58333 13.6667 7 12 7H11.6461L12.4692 7.8231C12.6141 7.96797 12.6865 8.14361 12.6865 8.35002C12.6865 8.55642 12.6141 8.73206 12.4692 8.87693C12.3244 9.02179 12.1487 9.09422 11.9423 9.09422C11.7359 9.09422 11.5603 9.02179 11.4154 8.87693L9.4212 6.8827C9.32761 6.78912 9.26159 6.6904 9.22312 6.58655C9.18466 6.4827 9.16542 6.37052 9.16542 6.25002C9.16542 6.12951 9.18466 6.01732 9.22312 5.91348C9.26159 5.80964 9.32761 5.71092 9.4212 5.61732L11.4154 3.6231C11.5603 3.47823 11.7359 3.4058 11.9423 3.4058C12.1487 3.4058 12.3244 3.47823 12.4692 3.6231C12.6141 3.76797 12.6865 3.94361 12.6865 4.15002C12.6865 4.35642 12.6141 4.53206 12.4692 4.67693L11.6461 5.50002H12C14.0923 5.50002 15.8654 6.22695 17.3192 7.6808C18.773 9.13463 19.5 10.9077 19.5 13C19.5 14.7333 18.9743 16.2667 17.923 17.6C16.8718 18.9333 15.523 19.8224 13.8769 20.2673Z"
        fill={color}
      />
    </svg>
  )
}
